import React, {useEffect, useRef, useState} from "react"
import "./style.css";
import 'flickity/css/flickity.css'
import Flickity from 'flickity';
import { Link } from "react-router-dom";

function ServiceComponent() {

    const [isHovered, setIsHovered] = useState(false);

    function changeBackground(e) {
        e.target.style.background = '#2563EB';
    }
    function changeBackgroundDefault(e) {
        e.target.style.background = 'white';
    }
        

    const carousel = useRef(null);
    useEffect(() => {
        const flkty = new Flickity(carousel.current, {
            lazyLoad: true,
            wrapAround: true,
            autoPlay: true,   
            prevNextButtons: false,
            pageDots: false,
            on: {
                // ready: function() {
                //     console.log("Nyahoo")
                // },
                // pointerDown: function(event){
                //     console.log("down", event.target)
                // },
                // pointerUp: function(event){
                //     console.log("up", event.target)
                // },
                staticClick: function(event){
                    window.location.href = event.target.dataset.target
                }
            }
        });
        // pakai ini untuk liat slide yang aktif flkty.selectedIndex
        const customDots = document.querySelectorAll('.custom-dot');
            customDots.forEach((dot, index) => {
            dot.addEventListener('click', () => {
                flkty.select(index);
            });
        });

        return () => {
            customDots.forEach((dot, index) => {
                dot.removeEventListener('click', () => {
                    flkty.select(index);
                });
            });
        };    
    }, []);

    return (
        <>
            <section id="additional-service-2" style={{position: "relative"}}>
                <div className="container">
                    <h2 className="big-title">We offer a wide variety of IT Solutions</h2>
                    <p className="medium-title">We takes into consideration every little detail to make sure the<br /> system run smoothly and responsively.</p>
                </div>
                
                    <div ref={carousel} className="main-carousel"> 
                        <div className="carousel-cell" data-target='/solution/DataCenterSolution'>
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1496.png'}/>
                            <div className="solution">
                                <span className="sm-blue-text">Data Center</span>
                                <span className="md-black-text">Data Center Infrastructure</span>
                                <span className="sm-hidden-text">Protect your business from threats and enable your business to run a new business. </span>
                            </div>
                        </div>
                        <div className="carousel-cell" data-target='/solution/NetworkSolution'>
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1495.png'}/> 
                            <div className="solution">
                                <span className="sm-blue-text">Network</span>
                                <span className="md-black-text">Enterprise Network Infrastructure</span>
                                <span className="sm-hidden-text">We provide management reporting and  support to keep information flowing. </span>
                            </div>
                        </div>
                        <div className="carousel-cell" data-target='/solution/SecuritySolution'>
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1497.png'}/>     
                            <div className="solution">
                                <span className="sm-blue-text">Network</span>
                                <span className="md-black-text">Enterprise Security Infrastructure</span>
                                <span className="sm-hidden-text">Protect your business from threats and enable your business to run a new business. </span>
                            </div>
                        </div>
                        <div className="carousel-cell" data-target='/notfound'>
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1498.png'}/> 
                            <div className="solution">
                                <span className="sm-blue-text">IoT</span>
                                <span className="md-black-text">IoT Solution</span>
                                <span className="sm-hidden-text">We make it easy with powerful IoT solutions and boosting your business productivity.</span>
                            </div>
                        </div>
                        <div className="carousel-cell" data-target='/notfound'>
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1499.png'}/> 
                            <div className="solution">
                                <span className="sm-blue-text">Services</span>
                                <span className="md-black-text">Professional Services</span>
                                <span className="sm-hidden-text">Our engineers have the skills and experience to support the entire system.</span>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    );
}

export default ServiceComponent;