import React, {Component} from "react"
import {ProjectDetailsComponent, CTAComponent} from "../components/"
import {urls} from '../utils/constants'
import axios from "axios"



export default class ProjectDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});

        axios(urls.API_URL+'mahasiswa', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
        }).then(response => {
            console.log(response.data)
            this.setState({ data: response.data })
        }).catch(function (error) {
            console.log(error)
        })

    }
    render() {
        return (
            <> 
                <ProjectDetailsComponent/>
                <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
            </>
        )
    }
}