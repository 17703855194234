import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import {urls} from '../../utils/constants'
import "./style.css";
import Button from "../ui/Button"
import ProjectCard from "./ProjectCard";

function ProjectComponent(props) {

    const [data, setData] = useState(0);
    // array kosong digunakan supaya request ketika ada perubahan saja 
    useEffect(() => {
        fetchProject();
    }, []);
    const fetchProject = async () => {
        axios(urls.API_URL+'dataProjects', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'same-origin',
            }).then(response => {
                console.log(response.data)
                setData(response.data)
            }).catch(function (error) {
                console.log(error)
            })
    }
    const goTo404Page = () => {
        window.location.href = '/notfound'
    }

    return (
        <>
            <section id="projects">
                <div className="container">
                    <div className="row mb-60-deskt">
                        <h2 className="big-title">Our Projects</h2>
                        <div className="col-md-6" style={{height: "70px", marginBottom: "64px"}}>
                            <p className="medium-title">We provide professional resources that carry out<br className="deskt"/> projects on time according to the target</p>
                        </div>
                        <div className="col-md-6" style={{position: "relative", display: "flex", justifyContent: "flex-end"}}>
                            <Link to="/project" style={{textDecoration: "none"}}>
                                <Button variant="primary">See All Projects</Button>
                            </Link>
                        </div>
                    </div>
                    
                    <ProjectCard data={data} />

                    <div className="row">
                        <button type="button" style={{width: "20rem", margin: "auto"}} className="btn-primary-gfs mob" onClick={goTo404Page}>See All Projects</button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProjectComponent;

    