import React, { useState, useEffect } from "react";
import axios from "axios"
import {urls} from '../../utils/constants'
import "./style.css";

const linkStyle = {
    color: "#101828",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    textDecoration: "none",
};

function ChainsawDetailComponent() {
    const [data, setData] = useState(0);
    // array kosong digunakan supaya request ketika ada perubahan saja 
    useEffect(() => {
        fetchProduct();
    }, []);
    const fetchProduct = async () => {
        axios(urls.API_URL+'chainsawdetail', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'same-origin',
            }).then(response => {
                console.log(response.data)
                setData(response.data)
            }).catch(function (error) {
                // console.log(error)
            })
    }
    return (
        <>
        <section id="chainsawdetail">
            <header>
                <div>
                    <h1>C66 Mobile Computer (Android 11)</h1>
                    <p>More Powerful, More Extensible, More Advanced in Every Way</p>
                </div>
            </header>
            <main>
                <h2>Mobile <br/>Computer Functions</h2>

                {/* grid */}
                <table cellPadding={10} cellSpacing={10} border={0}>
                    <tr style={{height: "200px"}}>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/barcode.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Barcode Scanning</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/camera.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;13MP Autofocus Camera</td>
                        <td rowSpan={2}><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/uhf.png'} style={{width: "384px", height: "550px", borderRadius: "16px", marginBottom: "-30px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Support UHF Sled</td>
                    </tr>
                    <tr>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/volume.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>Volume Measurement</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/nfc.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;NFC</td>
                    </tr>
                    <tr>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/rfid.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;UHF RFID</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/fingerprint.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Fingerprint Recognition</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/wifi.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Wi-Fi 6 Ready</td>
                    </tr>
                </table>

                {/* <table cellPadding={10} cellSpacing={10} border={0}>
                    <tr style={{height: "200px"}}>
                        <td><img src={process.env.PUBLIC_URL + '/img/assetchainsaw/barcode.png'} style={{ borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Barcode Scanning</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image2.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;13MP Autofocus Camera</td>
                        <td rowSpan={2}><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image3.png'} style={{ borderRadius: "16px", marginBottom: "-30px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Support UHF Sled</td>
                    </tr>
                    <tr>
                        <td><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image4.png'} style={{borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Volume Measurement</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image5.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;NFC</td>
                    </tr>
                    <tr>
                        <td><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image6.png'} style={{borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;UHF RFID</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image7.png'} style={{borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Fingerprint Recognition</td>
                        <td><img src={process.env.PUBLIC_URL + '/img/chainway-detail/image8.png'} style={{width: "384px", height: "264px", borderRadius: "16px", marginBottom: "-45px"}}/>&nbsp;&nbsp;&nbsp;&nbsp;Wi-Fi 6 Ready</td>
                    </tr>
                </table> */}

                <h2>Specifications</h2>
                <hr/>
                {data && data.map(d => (
                    <div className="wrapper_specifications">
                        <h3>{d.name_spec}</h3>
                        <p>{d.explain_spec}</p>
                    </div>
                ))}
            </main>
        </section>
        </>
    );
}

export default ChainsawDetailComponent;