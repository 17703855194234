import React, {Component} from "react"
import {BlueHeaderComponent, CTAComponent, BlogCard} from "../components/"

export default class Blog extends Component {
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        return (
            <>
                <BlueHeaderComponent/>
                <BlogCard/>
                <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
            </>
        )
    }
}