import "./SolutionDetailsStyle.css"
function SolutionDetails() {
    return (
        <>
            <section id="SolutionDetails">
                <div className="container">
                    <p><span className="parent">Enterprise Network Solution &nbsp;&gt;</span>  <span className="children">Enterprise Network</span></p>
                    <h1>Enterprise Network</h1>
                    <p>Streamline operations, enhance security, and boost productivity through a robust network infrastructure. Experience the future of networking today.</p>
                    <div className="hero_image">
                        
                    </div>
                    <h4>Unlock new possibilities with our Enterprise Network Solutions. </h4>
                    <p>Seamlessly connect, perform with excellence, and fortify your network's foundation for a successful future. Designed to meet the unique needs of large businesses and organizations. They provide a comprehensive set of features and functionality to help businesses improve their network performance, security, and reliability.</p>

                    <div className="card_wrapper">
                        <div className="card_solution">
                        <img src={process.env.PUBLIC_URL + '/img/Frame-1496.png'}/>
                            <div className="solution">
                                <p className="title">Higher Productivity</p>
                                <p className="description">Modern networking can dramatically improve employee productivity.</p>
                            </div>
                        </div>
                        <div className="card_solution">
                        <img src={process.env.PUBLIC_URL + '/img/Frame-1496.png'}/>
                            <div className="solution">
                                <p className="title">Increased Agility</p>
                                <p className="description">Increase the agility of your network by making it easier to add new users, devices, and applications.</p>
                            </div>
                        </div>
                        <div className="card_solution">
                        <img src={process.env.PUBLIC_URL + '/img/Frame-1496.png'}/>
                            <div className="solution">
                                <p className="title">Reduced Costs</p>
                                <p className="description">Easier, cost-effective, and includes solutions for analytics, monitoring, and security.</p>
                            </div>
                        </div>                        
                    </div>
                </div>
                
            </section>
        </>
    );
}

export default SolutionDetails;