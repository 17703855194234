import { Link } from "react-router-dom";
import "./style.css";
import "./script.js";

function AboutComponent() {
    const aboutImage1 = process.env.PUBLIC_URL + '/img/additional1.png'
    const aboutImage2 = process.env.PUBLIC_URL + '/img/additional2.png'
    return (
        <>
            <section id="additional-service-1">
                <div className="container">
                    <div className="row" id="text-about">
                        <div className="col-md-6" style={{marginBottom: "64px"}}>
                            <h2 className="big-title">We help you </h2>
                            <h2 className="big-title">to grow your business</h2>
                            <h2 className="big-title"><span style={{color: "#3B82F6", fontStyle: "italic"}}>exponentially.</span></h2>
                        </div>
                        <div className="col-md-6"></div>
                    </div>
                    <div className="row" id="additional-services-list">
                        <div className="col-md-6"> 
                            <div className="additional-service-image" style={{backgroundImage: `url(${aboutImage1})`, backgroundSize: 'cover', width: "100%"}}></div>
                            <p className="small-title">More than 10 years experience in IT professionals, we provide solutions for your business</p>
                            <Link to="/about" className="link-additional-about">About us</Link>
                        </div>
                        <div className="col-md-6">
                            <div className="additional-service-image" style={{backgroundImage: `url(${aboutImage2})`, backgroundSize: 'cover', width: "100%"}}></div>
                            <p className="small-title">We provide professional resources that carry out projects on time according to the target</p>
                            <Link to="/notfound" className="link-additional-project">Our projects</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutComponent;

    