import "./BlogCardStyle.css"
function BlogCard() {
    return (
        <>
            <section id="BlogCard">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="bg-blog"></div>
                            <span className="categ_time">Technology  •  8 min read</span>
                            <h2 className="blog-title">What is Cloud Computing?</h2>
                            <p className="description">Practice of using a network of remote servers hosted on the internet.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-blog"></div>
                            <span className="categ_time">Technology  •  8 min read</span>
                            <h2 className="blog-title">What is Cloud Computing?</h2>
                            <p className="description">Practice of using a network of remote servers hosted on the internet.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-blog"></div>
                            <span className="categ_time">Technology  •  8 min read</span>
                            <h2 className="blog-title">What is Cloud Computing?</h2>
                            <p className="description">Practice of using a network of remote servers hosted on the internet.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-blog"></div>
                            <span className="categ_time">Technology  •  8 min read</span>
                            <h2 className="blog-title">What is Cloud Computing?</h2>
                            <p className="description">Practice of using a network of remote servers hosted on the internet.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-blog"></div>
                            <span className="categ_time">Technology  •  8 min read</span>
                            <h2 className="blog-title">What is Cloud Computing?</h2>
                            <p className="description">Practice of using a network of remote servers hosted on the internet.</p>
                        </div>
                        <div className="col-md-4">
                            <div className="bg-blog"></div>
                            <span className="categ_time">Technology  •  8 min read</span>
                            <h2 className="blog-title">What is Cloud Computing?</h2>
                            <p className="description">Practice of using a network of remote servers hosted on the internet.</p>
                        </div>
                    </div>
                </div>

            </section>
        </>
    );
}

export default BlogCard;