import "./style.css"
function Career() {
    return (
        <>
            <section id="Career">
                <div className="head">
                    <h1>Make your great innovation come true with Nusa.</h1>
                </div>
                <div className="list_wrapper">
                    <div className="list_card">
                        <div className="description">
                            <p className="position">Penetration Tester</p>
                            <p className="place">Fulltie - Jakarta</p>
                        </div>
                        <button className="btn btn-primary">Apply</button>
                    </div>
                    <div className="list_card">
                        <div className="description">
                            <p className="position">Penetration Tester</p>
                            <p className="place">Fulltie - Jakarta</p>
                        </div>
                        <button className="btn btn-primary">Apply</button>
                    </div>
                    <div className="list_card">
                        <div className="description">
                            <p className="position">Penetration Tester</p>
                            <p className="place">Fulltie - Jakarta</p>
                        </div>
                        <button className="btn btn-primary">Apply</button>
                    </div>
                    <div className="list_card">
                        <div className="description">
                            <p className="position">Penetration Tester</p>
                            <p className="place">Fulltie - Jakarta</p>
                        </div>
                        <button className="btn btn-primary">Apply</button>
                    </div>
                    <div className="list_card">
                        <div className="description">
                            <p className="position">Penetration Tester</p>
                            <p className="place">Fulltie - Jakarta</p>
                        </div>
                        <button className="btn btn-primary">Apply</button>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Career;