import "./style.css";

function BlueHeaderComponent() {
    return (
        <>
            <section id="blueHeader">
                <div className="decor_ring"></div>
                <h1>Explore how Nusa Network Prakarsa plays a crucial role in ensuring the functionality of business worldwide.</h1>
            </section>
        </>
    );
}

export default BlueHeaderComponent;