import React, {Component} from "react"
import {ContactFormComponent, ContactInformationComponent} from "../components/"
export default class Contact extends Component {
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        return (
            <>
                <ContactFormComponent/>
                <ContactInformationComponent/>
            </>
        )
    }
}