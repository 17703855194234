import "./style.css";

function AboutUsDetailsComponent(props) {
    return (
        <>
            <section id="AboutUsDetailsComponent">
                <header>
                    <h1>We are IT Solution company with 10 years of experience.</h1>
                    <p>We deliver relevant solutions to businesses around the globe. Strong innovation to leverage your IT resource is built in our vein.​</p>
                    
                </header>
                <main>
                    <div className="about-nnp-team"></div>
                    <div>
                        <h2>History of<br/> Nusa Network Prakarsa</h2>
                        <p className="fl-left">PT. Nusa Network Prakarsa is a leading system integrator in Indonesia with over 10 years of experience in delivering innovative technology solutions to businesses across various industries.</p>
                        <p className="fl-right">With over a decade of experience as a trusted system integrator in Indonesia, PT. Nusa Network Prakarsa is dedicated to delivering advanced technology solutions that drive business growth and transformation.</p>
                    </div>
                    
                </main>
                <div className="achievement-nnp">
                    <h2>More than 10 years of excellence</h2>
                    <div>
                        <div className="achievement-ammount">
                            <div>
                                <h2>54+</h2>
                                <span>Projects</span>
                                <p>With an extensive track record of accomplishing lot of projects with great success throughout its history.</p>
                            </div>
                            <div>
                                <h2>100+</h2>
                                <span>Client</span>
                                <p>We recognize that every client is unique, with distinct goals, challenges, and requirements.</p>
                            </div>
                            <div>
                                <h2>32</h2>
                                <span>Brand Partner</span>
                                <p>We take pride in our wide range of brand collaborations that form our extensive portfolio.</p>
                            </div>
                            <div>
                                <h2>10</h2>
                                <span>Awards</span>
                                <p>We have been given a lot of awards and recognition for our commitment to excellence and great results.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="nnp-partner">
                    <h3>Our Technology Partner</h3>
                    
                    {/* <div className="bungkus">
                        <article className="wrapper">
                            <div className="marquee">
                                <div className="marquee__group">
                                    {props.dataBrandNusa.map(logo => (
                                        <img 
                                        style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                            key={logo.id}
                                            alt={`Logo ${logo.id}`}
                                            className="deskt" 
                                            src={process.env.PUBLIC_URL + logo.path} />
                                    ))}
                                </div>
                                <div aria-hidden="true" className="marquee__group">
                                    {props.dataBrandNusa.map(logo => (
                                        <img 
                                            style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%'}}
                                            key={logo.id}
                                            alt={`Logo ${logo.id}`}
                                            className="deskt" 
                                            src={process.env.PUBLIC_URL + logo.path} />
                                    ))}
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="bungkus">
                        <article className="wrapper">
                            <div className="marquee">
                                <div className="marquee__group anim-reverse">
                                    {props.dataBrandNusa.map(logo => (
                                        <img 
                                            style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                            key={logo.id}
                                            alt={`Logo ${logo.id}`}
                                            className="deskt" 
                                            src={process.env.PUBLIC_URL + logo.path} />
                                    ))}
                                </div>
                                <div aria-hidden="true" className="marquee__group anim-reverse">
                                    {props.dataBrandNusa.map(logo => (
                                        <img 
                                            style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                            key={logo.id}
                                            alt={`Logo ${logo.id}`}
                                            className="deskt" 
                                            src={process.env.PUBLIC_URL + logo.path} />
                                    ))}
                                </div>
                            </div>
                        </article>
                    </div> */}
                    <div className="deskt">
                        <div class="slider" style={{marginBottom: "92px"}}>
                            <div class="slide-track-reverse">

                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="deskt" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}
                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="mob" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}

                            </div>
                        </div>
                        <div class="slider">
                            <div class="slide-track">

                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="deskt" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}
                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="mob" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}

                            </div>
                        </div>
                    </div>
                    <div className="mob">
                    <div class="slider" style={{marginBottom: "24px"}}>
                            <div class="slide-track-reverse">

                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="deskt" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}
                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="mob" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}

                            </div>
                        </div>
                        <div class="slider">
                            <div class="slide-track">

                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="deskt" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}
                                {props.dataBrandNusa.map(logo => (
                                <div class="slide">
                                    <img 
                                    style={{marginRight:'92px', height: '80px', width: '180px', objectFit: 'contain', filter: 'brightness(0%)'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="mob" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUsDetailsComponent;

