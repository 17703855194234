import React, {Component} from "react"

const langs = [
    {
        id: 1,
        name: 'Indonesia',
        flag: 'https://www.countryflags.com/wp-content/uploads/indonesia-flag-png-large.png'
    },
    {
        id: 2,
        name: 'English',
        flag: 'https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png'
    }
]

export default class TermConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 1,
            openSelection: false,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        
    }
    setSelected = (val) => {
        this.setState({ selected: val });
    }
    setOpenSelection = (val) => {
        this.setState(prevState => ({
            openSelection: !prevState.openSelection
        }));
    }
    handleClickOutside = (event) => {
        const { openSelection } = this.state;
        const box = this.boxRef;

        if (box && !box.contains(event.target) && openSelection) {        
            this.setState({ openSelection: false });
        }
    }
    
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
        document.addEventListener('click', this.handleClickOutside);

    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
    render() {
        return (
            <>
            <div className="w-full flex justify-center my-10">
                <div className="border" ref={(ref) => { this.boxRef = ref; }}>
                    <button type="button" onClick={this.setOpenSelection} class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                        <span class="flex items-center">
                            <img src={langs[this.state.selected-1].flag} alt="" class="h-5 w-5 flex-shrink-0 rounded-full"/>
                            <span class="ml-3 block truncate">{langs[this.state.selected-1].name}</span>
                        </span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <svg class="h-5 w-5 text-gray-400 relative -top-1" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M10 17a.75.75 0 01-.55-.24l-3.25-3.5a.75.75 0 111.1-1.02L10 15.148l2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-.55.24z" clip-rule="evenodd" />
                            </svg>                        
                        </span>
                    </button>
                    {this.state.openSelection &&
                    <ul class="absolute z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                        {langs.map((lang) => (
                            <li class="text-gray-900 relative cursor-pointer select-none py-2 pl-3 pr-9" id="listbox-option-0" role="option" key={lang.id} onClick={() => this.setSelected(lang.id)}>
                                <div class="flex items-center mr-2">
                                    <img src={lang.flag} alt="" class="h-5 w-5 flex-shrink-0 rounded-full"/>
                                    <span class="font-normal ml-3 block truncate">{lang.name}</span>
                                </div>
                                {this.state.selected == lang.id &&
                                <span class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                                    <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                                    </svg>
                                </span>}
                            </li>
                        ))}
                    </ul>}
                </div>
            </div>
            {langs[this.state.selected-1].id == 1 &&
                <section className="flex flex-row justify-center" style={{marginRight: '20px',marginLeft: '20px',}}>
                <div style={{width: '800px'}}>
                    <h1 className="text-5xl lg:text-6xl font-semibold text-center text-custom-dark mb-8">Ketentuan Umum</h1>
                    <p className="text-lg font-medium text-custom-secondary mb-8">Bacalah seluruh ketentuan umum ini sebelum melakukan Pesanan Pembelian (PO) barang dan/atau jasa di PT. Nusa Network Prakarsa;</p>
                    <p className="text-lg font-medium text-custom-secondary mb-8">Selanjutnya, secara sadar menyetujui dan sepakat Ketentuan Umum ini mengatur hubungan antara Pemesan dan PT. Nusa Network Prakarsa ("<b>NNP</b>"), dengan ketentuan-ketentuan sebagaimana tertera di bawah ini yang dituangkan dalam bentuk elektronik berlaku untuk semua transaksi pemebelian secara langsung dan/atau secara elektronik Pesanan Pembelian (PO) barang dan/atau jasa;</p>
                    <p className="text-lg font-medium text-custom-secondary mb-8">NNP dan Pemesan masing-masing disebut sebagai "<b>Pihak</b>" dan secara bersama-sama disebut sebagai "<b>Para Pihak</b>"</p>

                    <p className="text-lg font-medium text-custom-secondary">Bahwa:</p>

                    <p className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3"><div>a.a.</div> <span><b>Ketentuan Umum</b> adalah suatu aturan umum mengatur Penawaran Harga (Quotation) atau Pesanan Pembelian (PO) barang dan/atau jasa baik secara langsung dan/atau elektronik (selanjutnya secara bersama-sama disebut sebagai "Ketentuan Umum";</span></p>

                    <p className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3"><div>a.b.</div> <span><b>NNP</b> adalah suatu badan hukum dan/atau perorangan, bertindak secara sah, menjalankan bisnisnya di bidang Perdagangan Besar Komputer Dan Perlengkapan Komputer, Perdagangan Besar Piranti Lunak, Perdagangan Besar Suku Cadang Elektronik, Perdagangan Besar Peralatan Telekomunikasi, Aktivitas Konsultasi Komputer Dan Manajemen Fasilitas Komputer Lainnya, Industri Perlengkapan Komputer;</span></p>

                    <p className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3"><div>a.c.</div> <span><b>Pemesan</b> adalah suatu badan hukum dan/atau perorangan, bertindak secara sah, menjalankan bisnisnya bermaksud untuk bertransaksi pemebelian langsung dan/atau elektronik menggunakan pesanan pembelian (PO) ditujukan kepada NNP;</span></p>

                    <p className="font-semibold text-custom-dark text-lg mb-8">Sehubungan dengan hal tersebut di atas, Pemesan setuju dan sepakat atas Ketentuan umum seperti sebagaimana tercantum di bawah ini:</p>
                    
                    <ul className="list-decimal pl-6">
                        <li className="font-bold text-custom-dark">DEFINISI
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.1.</div> <p><b>“Kontrak”</b> adalah suatu perikatan antara Pihak yang mengikatkan dirinya; Sehingga dari kontrak lahir suatu perikatan di mana Para Pihak yang mengikatkan diri memiliki kewajibannya masing-masing sesuai yang ditentukan dalam kontrak menjadi satu kesatuan dengan ketentuan umum ini; Namun jika terdapat perbedaan definisi dan/atau penafsiran maka Para Pihak tetap setuju dan sepakat ketentuan umum ini tetap berlaku;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.2.</div> <p><b>Pesanan Pembelian (PO)</b> adalah dokumen yang berisi permintaan kepada NNP untuk menyediakan barang dan/atau jasa, yang di dalam dokumen tersebut disertai rincian keterangan, di antaranya memuat nomor Pesanan Pembelian (PO), jenis, kuantitas barang yang dipesan, alamat pengiriman dan ditandatangani secara sah dianggap sebagai perjanjian dengan syarat dan ketentuan telah diatur di dalamnya atau di dalam ketentuan umum ini;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.3.</div> <p><b>“Penawaran Harga”</b> adalah surat penawaran tertulis dari NNP kepada Pemesan yang secara langsung diakui dan disepakati secara sah oleh Para Pihak mencantumkan jenis barang dan/atau jasa, rincian harga, jumlah, kuantitas, pembayaran, periode, serta syarat dan ketentuan lainnya yang diatur didalamnya; Namun dalam hal tidak tercantum pembayaran, periode, serta Syarat dan Ketentuan lainnya maka yang belaku adalah ketentuan umum ini;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.4.</div> <p><b>“Harga”</b> adalah total nominal uang/biaya barang dan/atau jasa yang harus dibayarkan oleh Pemesan kepada NNP yang terperinci tercantum dalam Pesanan Pembelian (PO) dan/atau Penawaran Harga; Merupakan hutang yang harus diakui oleh Pemesan dan dipertanggungjawabkan hingga ahli waris;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.5.</div> <p><b>“Rekening NNP”</b> adalah sederet nomor unik dari Bank yang berfungsi sebagai alat transaksi digital yang dimiliki NNP atas nama PT. Nusa Network Prakarsa tercatat dan dipertegas dalam Surat Tagihan (Invoice);</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.6.</div> <p><b>“Surat Tagihan (Invoice)”</b> adalah surat yang mendapatkan hak NNP untuk menagih pembayaran kepada pemesan yang berisi daftar barang kiriman yang dilengkapi dengan nama, jumlah, dan harga yang harus dibayar oleh pemesan kepada rekening NNP;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.7.</div> <p><b>“Garansi”</b> adalah jaminan/tanggungan yang diterbitkan/berikan oleh pabrik/agen dalam bentuk kartu atau bentuk lain menjadi tanggungjawab pabrik/agen atau ditentukan lain dalam Pesanan Pembelian (PO) atau Kontrak;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.8.</div> <p><b>“Informasi Rahasia”</b> adalah setiap informasi mengenai urusan bisnis, perkembangan, rahasia dagang, pengetahuan, personel, pemesan, spesifikasi, gambar, desain, deskripsi, informasi dan data teknis, dan semua informasi lain yang bersifat rahasia yang diberikan oleh NNP sehubungan kerahasiaan Pemesan;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.9.</div> <p><b>“Secara Langsung”</b> adalah suatu bentuk pemberian/ penyerahan/ pengiriman Pesanan Pembelian (PO) yang sudah di tandatangani secara sah dan/atau cap basah oleh Pelanggan langsung atau menggunakan jasa kurir kepada NNP;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.10.</div> <p>“Secara Elektronik” adalah suatu bentuk pemberian/ penyerahan/ pengiriman Pesanan Pembelian (PO) yang sudah di tandatangani secara sah dan cap basah oleh Pelanggan menggunakan jejaring komputer untuk mengirim dan menerima pesan dan/atau aplikasi percakapan/ pengiriman pesan sehingga Pesanan Pembelian (PO) menjadi dokumen elektronik;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.11.</div> <p><b>“Pesanan Pembelian (PO) fiktif”</b> adalah pesanan pembelian yang ditandatangani dan/atau tidak ditandatangani oleh Pihak yang seolah-olah sah dengan sengaja dan tanpa hak atau melawan hukum melakukan hal tersebut dianggap seolah-olah data yang dipesan otentik;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>1.12.</div> <p><b>“Keadaan Kahar”</b> adalah keadaan di luar kendali yang wajar dari Pihak yang terkena dampak, termasuk, namun tidak terbatas pada, penolakan atau pencabutan izin, perselisihan industrial, ketidakmungkinan memperoleh materi, pemogokan oleh karyawan Pihak Ketiga, kebakaran, perang, tindakan Tuhan, kontrol pemerintah, wabah penyakit: epidemi dan pandemic.</p>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">KETENTUAN
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>2.1.</div> <p>NNP akan mempelajari spesifikasi dan menandatangani Pesanan Pembelian (PO) yang dibutuhkan setelah Pemesan mengirim langsung dan/atau secara elektronik sesuai dengan Penawaran Harga (“Quotation”), atau Pemesan menerbitan Pesanan Pembelian (PO); Dengan hal ini sudah disepakati bersama tidak dapat dibatalkan dan tidak dapat diubah/ diganti terkait ruang lingkupnya secara sepihak;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>2.2.</div> <p>Jika Pesanan Pembelian (PO) dibatalkan oleh Pemesan secara sepihak; Maka Pemesan setuju dan sepakat NNP berhak secara sah mengenakan <b>penality 30% (tiga puluh persen)</b> dari harga Pesanan Pembelian (PO); Namun jika ada Pesanan Pembelian (PO) yang NNP tidak dapat memenuhinya; Maka NNP berhak menolak dan tidak menandatangani Pesanan Pembelian (PO) tersebut.</p>
                                </li>
                            </ul>
                        </li>
                        
                        <li className="font-bold text-custom-dark">JANGKA WAKTU
                            <ul className="list-none">
                                <li className="text-lg font-medium text-custom-secondary mb-8">Ketentuan umum berlaku secara terus menerus sampai dengan kewajiban masing-masing Pihak terpenuhi dan/atau kecuali ditentukan lain dalam Pesanan Pembelian (PO) atau Kontrak.</li>
                            </ul>
                        </li>

                        <li className="font-bold text-custom-dark">HARGA, PENAGIHAN DAN CARA PEMBAYARAN
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.1.</div> <p>Harga sudah termasuk pajak dan biaya-biaya terkait lainnya atau ditentukan lain dalam Kontrak, Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO);</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.2.</div> <p>Jika ditentukan pembayaran langsung; Pemesan harus membayar kepada NNP paling lambat 7 (tujuh) hari kerja; atau ditentukan lain dalam Pesanan Pembelian (PO) atau Kontrak;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.3.</div> <p>Pemesan harus membayar kepada NNP dengan harga yang ditentukan dalam Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) dengan cara pembayaran yang ditentukan dalam Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) atau dalam ketentuan umum ini;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.4.</div> <p>NNP dapat melakukan Penagihan kepada Pemesan sesuai yang ditentukan dalam Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) atau ditentukan lain dalam Kontrak atau dalam ketentuan umum ini;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.5.</div> <p>Pemesan harus membayar semua harga secara tepat waktu tanpa kompensasi atau pengurangan apapun yang telah ditentukan dalam surat Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) atau ditentukan lain dalam Kontrak;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.6.</div> <p>Pemesan dapat melakukan pembayaran harga dengan cara: 
                                        <ul className="list-decimal">
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>a.</div> <p>Langsung/tunai dan/atau via transfer bank sebagaimana terinci dalam surat tagihan 100% (seratus persen) seketika sebelum atau sesudah barang dan/atau jasa sampai pada pemesan;</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>b.</div> <p>tempo jangka waktu sebelum atau sesudah barang dan/atau jasa sampai pada pemesan;</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>c.</div> <p>memindahbukukan rekening ke NNP sebagaimana terinci dalam surat tagihan; atau</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>d.</div> <p>ditentukan lain dalam Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) atau ditentukan lain dalam Kontrak.</p>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.7.</div> <p>Tempo Jangka waktu yang dimaksud angka 4.5 huruf b dengan termin pembagian persentase hingga sampai pada pelunasan pembayaran 100% (seratus persen);</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.8.</div> <p>
                                    Pelanggan bertanggung jawab untuk membayar denda keterlambatan pembayaran atas jumlah tagihan yang belum dibayarkan yang sudah jatuh tempo yang telah ditentukan bersama dalam Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) dengan <b>tarif 2% (dua persen) Per-bulan</b>;
                                    </p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>4.9.</div> <p>Apabila Pelanggan tidak melakukan pembayaran sampai tanggal jatuh tempo; Maka NNP berhak melakukan upaya hukum tanpa menunggu adanya putusan pengadilan (inkcracht) mengambil/ menarik/ menghentikan/ menyita/ barang dan/atau jasa atau upaya hukum lain sampai diterimanya pelunasan pembayaran dan tidak ada penggantian apapun selama masa tersebut.
</p>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">PAJAK
                            <ul className="list-none">
                                <li className="text-lg font-medium text-custom-secondary mb-8">Semua biaya dan bea atau jumlah dan segala pajak yang timbul sehubungan dengan Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) menjadi tanggungan masing-masing Pihak sesuai dengan ketentuan perpajakan yang berlaku di Indonesia.</li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">WAKIL PARA PIHAK
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>6.1.</div> <p>NNP akan menujuk seorang atau beberapa orang wakil NNP yang mempunyai kewenangan untuk bertindak untuk dan atas nama NNP terkait Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO);</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>6.2.</div> <p>Pemesan akan menunjuk seorang atau beberapa orang wakil Pemesan yang mempunyai kewenangan untuk bertindak untuk dan atas nama Pemesan terkait dengan Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO), yang setiap saat atas kehendak Pemesan dapat dilakukan penggantian secara sah.</p>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">KEWAJIBAN PEMESAN
                            <p className="text-lg font-medium text-custom-secondary">Pemesan menyetujui, sepakat, serta berjanji untuk: </p>
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>a.</div> <p>Mengisi Formulir Aplikasi Customer NNP sebelum dan/atau sesudah Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) dibuat;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>b.</div> <p>Wajib mengirim/ memberikan/ melampirkan/ melengkapi Form Aplikasi Customer berupa fotocopy/scan dokumen-dokumen badan hukum dan/atau perorangan yang sah kepada NNP.</p>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">KERAHASIAAN
                            <ul className="list-none">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>8.1.</div> <p>Informasi yang diberikan oleh Pemesan Kepada NNP (baik tertulis dan elektronik) untuk keperluan Pemesanan digolongkan sebagai data atau informasi rahasia (<b>“Informasi Rahasia”</b>) dan dapat digunakan semata‐mata untuk Pelaksanaan pemesanan;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>8.2.</div> <p>Setiap saat Pemesan berhak meminta kembali Informasi Rahasia tersebut dan NNP harus mengembalikan semua Informasi rahasia dan memusnahkan semua salinan atau turunan setelah diminta oleh Pemesan, kecuali jika disetujui lain oleh Pemesan;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>8.3.</div> <p>Para Pihak dan/atau afiliasinya wajib saling menjaga Informasi Rahasia;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div className="">8.4.</div> 
                                    <div className="flex flex-col">
                                        <p>Informasi Rahasia tidak mencakup:</p>
                                        <ul className="list-decimal">
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>a.</div> <p>Informasi yang berada di ranah publik atau masuk ke ranah publik, bukan karena pelanggaran terhadap ketentuan umum ini oleh satu pihak;</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>b.</div> <p>Informasi yang sudah tersedia bagi satu Pihak atas dasar bukan rahasia sebelum Pemesanan diadakan;</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>c.</div> <p>Informasi yang diwajibkan untuk dibuka berdasarkan Undang‐undang, perintah pemerintah, keputusan, peraturan, atau aturan kepada mana satu Pihak atau perusahaan induknya tunduk;</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">KEADAAN KAHAR
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>9.1.</div> <p>Para Pihak saling membebaskan tanggung jawab atas kegagalan atau keterlambatan dalam melaksanakan kewajibannya menurut ketentuan umum ini, yang disebabkan oleh hal‐hal diluar kemampuan yang wajar dari Para Pihak dan bukan disebabkan kelalaian atau kesalahan Para Pihak, yang selanjutnya dalam ketentuan umum ini disebut Keadaan Kahar, kecuali menyelesaikan kewajiban masing‐masing Pihak yang timbul sebelum terjadinya Keadaan Kahar;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                <div className="">9.2.</div> 
                                    <div className="flex flex-col">
                                        <p>Untuk tujuan‐tujuan ketentuan umum ini, suatu kejadian Keadaan Kahar akan dianggap termasuk, tetapi tidak terbatas pada, hal‐hal berikut ini:</p>
                                        <ul className="list-decimal">
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>a.</div> <p>kerusuhan, perang, huru‐hara, pemberontakan atau sabotase, invasi, tindakan musuh asing, permusuhan, tindakan‐tindakan terorisme, perang, saudara, pemberontakan, revolusi, huru‐hara militer atau perampasan kekuasaan (baik dengan pernyataan perang maupun tidak), penyitaan atau pengambilalihan berdasarkan perintah‐perintah dari otoritas mana pun;</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>b.</div> <p>gempa bumi, banjir besar, tsunami, wabah penyakit: epidemi dan pandemi, dan bencana alam fisik lainnya, tetapi tidak termasuk kondisi‐kondisi cuaca tanpa memperhatikan tingkat keparahannya dan dengan bukti oleh instansi yang berwenang;</p>
                                            </li>
                                            <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                <div>c.</div> <p>pemogokan‐pemogokan di tingkat nasional atau daerah atau sengketa-sengketa industri di tingkat nasional atau daerah atau pemogokan‐pemogokan atau sengketa‐sengketa industri oleh buruh yang tidak dipekerjakan oleh Pihak yang terdampak.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>9.3.</div> <p>Pihak yang mengalami Keadaan Kahar harus segera memberitahukan Pihak lainnya secara lisan dalam waktu 1x24 jam dan diikuti dengan pemberitahuan tertulis selambat‐lambatnya dalam waktu 2x24 jam setelah terjadinya Keadaan Kahar tersebut, disertai dengan bukti atau keterangan resmi instansi berwenang dan perkiraan atau upaya‐upaya atau rencana‐rencana yang akan atau telah dilakukan dalam rangka meredakan dan mengatasi Keadaan Kahar tersebut;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>9.4.</div> <p>Pihak yang diberitahu dapat menolak atau menyetujui Keadaan Kahar tersebut selambat‐lambatnya dalam waktu 2x24 jam setelah diterimanya pemberitahuan Keadaan Kahar dari Pihak yang menyatakan Keadaan Kahar tersebut;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>9.5.</div> <p>NNP dan Pemesan akan mengadakan pertemuan tanpa ditunda untuk membahas kejadian Keadaan Kahar dan bersama‐sama memperkirakan kemungkinan jangka waktu gangguan dalam syarat dan ketentuan ini dengan tujuan untuk menyepakati arah tindakan yang dapat diterima bersama untuk meminimalkan setiap dampak dari kejadian tersebut yang memungkinkan dilanjutkannya kembali kegiatan‐kegiatan operasional; Kecuali sebagaimana secara tegas ditentukan lain dalam Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) dan/atau Kontrak, pembayaran‐pembayaran dengan sifat apapun tidak akan dilakukan terkait dengan suatu Kejadian Keadaan Kahar;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>9.6.</div> <p>Apabila suatu kejadian Keadaan Kahar berlangsung selama lebih dari 30 (tiga puluh) hari kalender berturut‐turut; Para Pihak akan mengadakan pertemuan untuk membahas apakah NNP Dan Pemesan akan mengakhiri Pemesanan atau tetap melanjutkan ruang lingkup untuk jangka waktu selanjutnya berdasarkan kesepakatan Para Pihak.</p>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">SYARAT DAN PENGHENTIAN
                            <ul className="list-none">
                                <li className="text-lg font-medium text-custom-secondary mb-8">Jika salah satu Pihak mengajukan permohonan untuk likuidasinya atau mengadakan rapat untuk mengusulkan keputusan likuidasinya atau mengajukan permohonan untuk pengangkatan seorang pengurus atau telah ditunjuk seorang kurator atau kurator administratif atasnya atau salah satu kekayaannya atau membuat perjanjian sukarela dengan para krediturnya; Maka Pihak lain dapat segera mengakhiri Pesanan dengan pemberitahuan tertulis kepada pihak yang disebutkan pertama.</li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">PEMBERITAHUAN
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>11.1.</div> <p>Setiap komunikasi sehubungan dengan Surat Penawaran Harga dan/atau Surat Pesanan Pembelian (PO) dapat dilakukan melalui surat, atau surat elektronik;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>11.2.</div> <p>Komunikasi melalui surat akan dianggap telah diterima oleh salah satu pihak dalam waktu tujuh (7) hari kalender setelah pengiriman (melalui jasa pengiriman atau surat elektronik) atau setelah pengiriman (jika dikirimkan secara pribadi) ke alamat yang diberitahukan sebelum pengiriman atau pengiriman oleh pihak tersebut;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>11.3.</div> <p>Komunikasi surat elektronik akan dianggap telah diterima oleh suatu pihak setelah dikirimkan ke alamat surat elektronik yang diberitahukan sebelumnya oleh pihak tersebut dengan diterimanya laporan pengiriman yang sesuai.</p>
                                </li>
                            </ul>
                        </li>
                        <li className="font-bold text-custom-dark">HUKUM YANG BERLAKU DAN PENYELESAIAN PERSELISIHAN
                            <ul className="list-decimal">
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>12.1.</div> <p>Ketentuan umum ini tunduk pada hukum dan peraturan perundang‐undangan yang berlaku di Indonesia;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>12.2.</div> <p>Tiap dan semua perselisihan yang timbul sehubungan dengan Ketentuan umum ini, akan diselesaikan dengan cara musyawarah untuk mufakat diantara Para Pihak dalam waktu paling lambat 30 (tiga puluh) hari kalender setelah diterimanya pemberitahuan tertulis dari Pihak yang berkepentingan;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>12.3.</div> <p>Setiap semua perselisihan antara NNP dengan Pemesan setelah 30 (tiga puluh) hari kalender sesuai ketentuan 12.2 tidak dapat diselesaikan secara musyawarah mufakat, NNP berhak melakukan upaya sesuai ketentuan 4.9 atau;</p>
                                </li>
                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div>12.4.</div> <p>Diselesaikan melalui Pengadilan Negeri Jakarta Barat dan/atau Badan Arbitrasi Nasional Indonesia (BANI) di Jakarta yang beralamat di Jl. Mampang Prapatan Raya Jakarta Selatan untuk diselesaikan melalui peraturan dan prosedur menurut BANI dengan menunjuk 3 (tiga) orang Arbiter; Kedudukan hukum adalah di Jakarta dan diselenggarakan dengan menggunakan bahasa Indonesia.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                </section>
            }
            {langs[this.state.selected-1].id == 2 &&
                <section className="flex flex-row justify-center" style={{marginRight: '20px',marginLeft: '20px',}}>
                    <div style={{width: '800px'}}>
                        <h1 className="text-6xl font-semibold text-center text-custom-dark mb-8">General Terms</h1>
                        <p className="text-lg font-medium text-custom-secondary mb-8">Read all of these general terms before placing a Purchase Order (PO) for goods and/or services at Nusa Network Prakasa, PT;</p>
                        <p className="text-lg font-medium text-custom-secondary mb-8">Furthermore, consciously agree and agree that these general terms govern the relationship between the Subscriber and PT. Nusa Network Prakarsa ("<b>NNP</b>"), with the conditions as stated below which are set forth in electronic form, applies to all direct and/or electronic purchase transactions Purchase Orders (PO) for goods and/or services;</p>
                        <p className="text-lg font-medium text-custom-secondary mb-8">NNP and the User are referred to as "<b>Party</b>" and collectively the "<b>Parties</b>"</p>

                        <p className="text-lg font-medium text-custom-secondary">That:</p>

                        <p className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3"><div>a.a.</div> <span><b>General Terms</b> is a general rule governing the Quotation or Purchase Order (PO) of goods and/or services either directly and/or electronically (hereinafter collectively referred to as "<b>General Terms</b>";</span></p>

                        <p className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3"><div>a.b.</div> <span><b>NNP</b> is a legal entity and/or individual, acting legally, running its business in the field of Wholesale of Computers and Computer Equipment, Wholesale of Software, Wholesale of Electronic Parts, Wholesale of Telecommunication Equipment, Computer Consulting Activities and Management of Other Computer Facilities, Computer Equipment Industry;</span></p>

                        <p className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3"><div>a.c.</div> <span><b>Customer</b> is a legal entity and/or individual, acting legally, running its business intending to transact direct and/or electronic purchases using a purchase order (PO) addressed to NNP;</span></p>

                        <p className="font-semibold text-custom-dark text-lg mb-8">In connection with the above, the subscriber agrees and agrees to the General terms as stated below:</p>
                        
                        <ul className="list-decimal pl-6">
                            <li className="font-bold text-custom-dark">DEFINISI
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.1.</div> <p>"<b>Contract</b>" is an agreement between the Parties that bind themselves; So that from the contract an engagement is born in which the parties who bind themselves have their respective obligations as specified in the contract into a single entity with these general terms; However, if there are differences in definitions and/or interpretations, then the Parties still agree and agree that these general terms remain valid;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.2.</div> <p>"<b>Purchase Order (PO)</b>" is a document containing a request to NNP to provide goods and/or services, which in the document is accompanied by detailed information, including the Purchase Order (PO) number, type, quantity of goods ordered, delivery address and legally signed is considered an agreement with the terms and conditions set out therein or in these general terms;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.3.</div> <p>"<b>Quotations (PO)</b>" is a written offer letter from NNP to a customer that is directly recognized and legally agreed upon by the Parties stating the types of goods and/or services, details of prices, quantities, quantities, payments, periods, and other terms and conditions stipulated therein; However, in the event that the payment, period, and other Terms and Conditions are not listed, then these general terms will apply;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.4.</div> <p>"<b>Price (PO)</b>" is the total nominal amount of money/cost of goods and/or services that must be paid by the Buyer to the NNP which is detailed in the Purchase Order (PO) and/or Price Offer; It is a debt that must be recognized by the customer and is accounted for to the heirs;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.5.</div> <p>"<b>Account Number NNP</b>" is a series of unique numbers from the Bank which functions as a digital transaction tool owned by NNP on behalf of PT. Nusa Network Prakarsa is recorded and confirmed in the Invoice;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.6.</div> <p>"<b>Invoice</b>" is an NNP letter which obtains the right to collect payment from the orderer which contains a list of consignment items accompanied by the name, quantity and price that must be paid by the orderer to the NNP account;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.7.</div> <p>"<b>Guarantee</b>" is a guarantee/coverage issued/provided by the factory/agent in the form of a card or other form which is the responsibility of the factory/agent or as otherwise specified in the Purchase Order (PO) or Contract;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.8.</div> <p>"<b>Confidential Information</b>" is any information regarding business affairs, developments, trade secrets, knowledge, personnel, orders, specifications, drawings, designs, descriptions, technical information and data, and all other confidential information provided by NNP in connection with customer confidentiality;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.9.</div> <p>"<b>Directly</b>" is a form of giving/ delivery/ delivery of Purchase Orders (PO) that have been legally signed and/or wet stamped by the Customer or use courier services to NNP;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.10.</div> <p>"<b>Electronically</b>" is a form of giving/ submission/ delivery of Purchase Order (PO) which has been legally signed and wet stamped by the Customer using a computer network to send and receive messages and/or a conversation/ message application so that the Purchase Order (PO) becomes an electronic document;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.11.</div> <p>"<b>Fictious Purchase Order (PO)</b>" is a purchase order that is signed and/or unsigned by the Party as if it is legitimate intentionally and without rights or against the law doing so is considered as if the ordered data is authentic;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>1.12.</div> <p>"<b>Force Majeure</b>" means circumstances beyond the reasonable control of a party affected, including, but not limited to, refusal or evocation of license, industrial dispute, impossibility of obtaining materials, strikes by employees of a third party, fires, wars, acts of God, governmental controls, disease: epidemic and pandemic.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">TERM
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>2.1.</div> <p>NNP will study the specifications and sign the required Purchase Order (PO) after the Buyer sends directly and/or electronically in accordance with the Quotation, or the Customer issues the Purchase Order (PO) without a Quotation ("Quotation"); With this it has been mutually agreed that it cannot be canceled and cannot be changed/ changed regarding its scope unilaterally;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>2.2.</div> <p>"If the Purchase Order (PO) is canceled by the Customer unilaterally; Then the Buyer agrees and agrees that NNP is legally entitled to impose a <b>penalty of 30% (thirty percent)</b> of the Purchase Order (PO) price; However, if there is a Purchase Order (PO) that NNP cannot fulfill; Then NNP has the right to refuse and not sign the Purchase Order (PO).</p>
                                    </li>
                                </ul>
                            </li>
                            
                            <li className="font-bold text-custom-dark">TERM PERIOD
                                <ul className="list-none">
                                    <li className="text-lg font-medium text-custom-secondary mb-8">General terms apply continuously until the obligations of each Party are fulfilled and/or unless otherwise specified in the Purchase Order (PO) or Contract.</li>
                                </ul>
                            </li>

                            <li className="font-bold text-custom-dark">PRICE, BILLING & PAYMENT TERM
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.1.</div> <p>Prices are inclusive of taxes and other related costs or otherwise specified in the Contract, Quotation Letter and/or Purchase Order (PO);</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.2.</div> <p>If direct immediate payment; Customer must pay NNP no later than 7 (seven) working days; or unless otherwise specified in the Purchase Order (PO) or Contract;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.3.</div> <p>Customer must pay to NNP at the price specified in the Quotation Letter and/or Purchase Order (PO) with the payment method specified in the Quotation Letter and/or Purchase Order Letter (PO) or in these general terms;
    </p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.4.</div> <p>NNP can perform Billing to the Buyer as specified in the Quotation Letter and/or Purchase Order (PO) or otherwise specified in the Contract or in these general terms;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.5.</div> <p>Customer must pay all prices in a timely manner without any compensation or deductions specified in the Quotation Letter and/or Purchase Order (PO) or otherwise specified in the Contract;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.6.</div> <p>The customer can pay the price by: 
                                            <ul className="list-decimal">
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>a.</div> <p>Directly/cash and/or via bank transfer as detailed in the 100% (one hundred percent) invoice immediately before or after the goods and/or services arrive at the customer;</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>b.</div> <p>the period of time before or after the goods and/or services arrive at the customer;</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>c.</div> <p>transfer the account to the NNP as detailed in the invoice; or</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>d.</div> <p>otherwise specified in the Quotation Letter and/or Purchase Order (PO) or otherwise specified in the Contract.</p>
                                                </li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.7.</div> <p>The period referred to in number 4.5 letter b with a percentage distribution term until it reaches 100% (one hundred percent) full payment;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.8.</div> <p>
                                        The customer is responsible for paying late payment penalties for the amount of unpaid bills that are due which have been determined together in the Quotation Letter and/or Purchase Order (PO) <b>at a rate of 2% (two percent) every month</b>;
                                        </p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>4.9.</div> <p>If the Customer does not make payment by the due date; Therefore, NNP has the right to take legal remedies without waiting for a court decision <i>(inkcracht)</i> to take/ withdraw/ stop/ confiscate /goods and/or services or other legal remedies until payment is received and there will be no reimbursement of any kind during this period.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">TAX
                                <ul className="list-none">
                                    <li className="text-lg font-medium text-custom-secondary mb-8">All fees and duties or amounts and all taxes incurred in connection with the Quotation Letter and/or Purchase Order (PO) shall be borne by each Party in accordance with the applicable tax provisions in Indonesia.</li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">REPRESENTATIVES OF THE PARTIES
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>6.1.</div> <p>The NNP will appoint one or several NNP representatives who have the authority to act for and on behalf of the NNP regarding the Quotation Letter and/or Purchase Order (PO);</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>6.2.</div> <p>The Customer will appoint one or several representatives of the Customer who have the authority to act for and on behalf of the Customer in relation to the Quotation Letter and/or Purchase Order (PO), which at any time at the will of the Buyer can be legally replaced.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">CUSTOMER OBLIGATIONS
                                <p className="text-lg font-medium text-custom-secondary">The customer agree, and promise to: </p>
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>a.</div> <p>Fill in the NNP Customer Application Form before and/or after the Quotation Letter and/or Purchase Order (PO) is made;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>b.</div> <p>Required to send/ provide/ attach/ complete the Customer Application Form in the form of copies/scan documents of legal entities and/or legal individuals to NNP.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">CONFIDENTIALTY
                                <ul className="list-none">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>8.1.</div> <p>Information provided by The Customer to NNP for the purposes of the Ordering shall be treated as confidential data/information ("<b>Confidential Information</b>") and may be used solely for the implementation of Orders;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>8.2.</div> <p>Customer is, at any time, entitled to reclaim the said Confidential Information from NNP and NNP shall return all Confidential information and destroy all copies or its derivatives (both written and electronic) of the same as requested by Customer, unless otherwise agreed by Customer;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>8.3.</div> <p>The Parties and/or their affiliates are obliged to safeguard each other's Confidential Information;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div className="">8.3.</div> 
                                        <div className="flex flex-col">
                                            <p>Confidential Information does not include:</p>
                                            <ul className="list-decimal">
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>a.</div> <p>Information which has been the public domain or into the public domain, not because of a violation of this General terms by either Party;</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>b.</div> <p>Information which has been available to either Parties on the basis of non‐confidential before entering into Orders;</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>c.</div> <p>Information which must be disclosed under the law, government orders, decisions, regulations, or rules to which either Party or its parent company is subject to;</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">FORCE MAJEURE
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>9.1.</div> <p>The Parties release each other from liability for failure or delay in carrying out their obligations under these general terms, caused by things beyond the reasonable capabilities of the Parties and not due to negligence or fault of the Parties, hereinafter referred to in these general terms. Force Majeure, except to settle the obligations of each Party that arose before the occurrence of Force Majeure;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                    <div className="">9.2.</div> 
                                        <div className="flex flex-col">
                                            <p>For the purposes of these general terms, an event of Force Majeure shall be deemed to include, but is not limited to, the following:</p>
                                            <ul className="list-decimal">
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>a.</div> <p>unrests, wars, riots, insurrections or sabotages, invasions, acts of foreign enemy, hostilities, terrorism, civil wars, rebellions, revolutions, insurrections or military seizure of power (whether declared or not), confiscations or expropriations by order of any authority;</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>b.</div> <p>earthquakes, large floods, tsunamis, epidemic and pandemic, or other physical natural disasters, but excluding weather conditions regardless of the severity and with evidence by the competent authority;</p>
                                                </li>
                                                <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                                    <div>c.</div> <p>strikes at national or regional level or industrial disputes at national or regional level or strikes or industrial disputes by the workers who are not employed by the affected Party.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>9.3.</div> <p>The Party affected by Force Majeure shall immediately notify the others orally within 1x24 hours and followed by written notice no later than 2 x 24 hours after the occurrence of the said Force Majeure, accompanied with an official statement of the competent authority and forecasts or attempts or plans that will be or have been made in order to alleviate and overcome the said Force Majeure;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>9.4.</div> <p>The notified Party may reject or approve The Force Majeure within 2 x 24 hours after receipt of The Force Majeure notice from the Party stating such Force Majeure In the event of such Force Majeure;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>9.5.</div> <p>FNNP and Customer shall have meeting without delay to discuss the events and together estimate the possible duration of interruptions of this term and condition with the purpose to get a mutual agreement on acceptable actions to be taken to minimize any impact of the incident which allows a resumption of operational activities; Except as expressly provided otherwise in the Quotation Letter and/or Purchase Order (PO) and/or Contract, payments of any nature will not be made in connection with a Force Majeure Event;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>9.6.</div> <p>If an event of Force Majeure lasts for more than 30 (thirty) consecutive calendar days; The Parties shall hold a meeting to discuss whether NNP and Customer will terminate the Orders or continue the Scope for a further period at the mutual agreement of the Parties.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">TERM AND TERMINATION
                                <ul className="list-none">
                                    <li className="text-lg font-medium text-custom-secondary mb-8">If either party has a petition presented for its liquidation or calls a meeting to propose a resolution for its liquidation or has a petition presented for the appointment of an administrator or has a receiver or administrative receiver appointed over it or any of its assets or makes any voluntary arrangement with its creditors; Then the other party may immediately terminate this orders by written notice to the first- mentioned party.</li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">NOTICES
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>11.1.</div> <p>Any communication in connection with a Quotation and/or Purchase Order (PO) can be made by letter, or electronic mail;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>11.2.</div> <p>Communications by letter will be deemed to have received by a party within seven (7) calendar days of posting (by email) or upon delivery (via delivery service or e-mail) to the address notified in advance of posting or delivery by that party;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>11.3.</div> <p>Electronic mail communications will be deemed to have been received by a party upon transmission to an electronic mail address notified in advance of transmission by that party with the receipt of the appropriate delivery report.</p>
                                    </li>
                                </ul>
                            </li>
                            <li className="font-bold text-custom-dark">GOVERNING LAW AND DISPUTE SETTLEMENT
                                <ul className="list-decimal">
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>12.1.</div> <p>General terms shall be subject to the Indonesian prevailing laws and regulations;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>12.2.</div> <p>Any and all disputes arising from or in connection with this General terms, shall be settled by way of amicable discussion between the Parties hereto no later than 30 (thirty) Calendar Days following the receipt of written notice from the Party;</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>12.3.</div> <p>Any dispute between NNP and Customer domiciled in or duly established under the laws of the Republic of Indonesia that cannot be resolved by consensus manner shall be resolved through the West Jakarta District Court and/or Badan Arbitrasi Nasional Indonesia (BANI) in Jakarta, having its address at Jl. Mampang Prapatan Raya South Jakarta to be resolved through the rules and procedures according to BANI by appointing 3 (three) Arbitrators; Legal domicile is in Jakarta and administered in the Indonesian language.</p>
                                    </li>
                                    <li className="text-lg font-medium text-custom-secondary mb-8 flex flex-row gap-x-3">
                                        <div>12.4.</div> <p>Resolved through the West Jakarta District Court and/or the Indonesian National Arbitration Board (BANI) in Jakarta at Jl. Mampang Prapatan Raya South Jakarta to be resolved through regulations and procedures according to BANI by appointing 3 (three) Arbitrators; The legal position is in Jakarta and is held using Indonesian.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </section>
            }
            </>
        )
    }
}