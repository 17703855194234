import "./style.css";

function IoTSolutions() {
    const aboutImage2 = process.env.PUBLIC_URL + '/img/Frame-1428.svg'
    return (
        <>
            <section id="IoTSolution">
                <div className="d-flex">
                    <div className="w-70-percent">
                        <h1 className="c-blue">Smart Ecosystem</h1>
                        <h1>Monitoring Dashboard</h1>
                    </div>
                    <div>
                        <p>Platform monitoring lingkungan pintar yang terintegrasi</p>
                        <button className="btn btn-primary">Learn More</button>
                    </div>
                </div>
                <div style={{backgroundImage: `url(${aboutImage2})`, backgroundSize: 'cover', width: "100%", height: "480px", marginTop: "96px"}}></div>
                <p className="text-center pt-96">999+ Organizations trust NADA</p>
                                
            </section>
        </>
    );
}

export default IoTSolutions;