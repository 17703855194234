import "./BlogDetailsStyle.css"
function BlogDetails() {
    return (
        <>
            <section id="BlogDetails">
                <div className="container">
                    <hgroup>
                        <p className="categandminutes">Technology  •  8 min read</p>
                        <h1>What is Cloud Computing?</h1>
                        <p>Practice of using a network of remote servers hosted on the internet.</p>
                        <div className="author_details">
                            <div className="image"></div>
                            <div className="desc">
                                <p className="name">John Doe</p>
                                <p className="date">1 January 2024</p>
                            </div>
                        </div>
                    </hgroup>
                </div>
                <div className="banner">

                </div>
                <div className="container">
                    <article>
                        <h3>Introduction</h3>
                        <p>Lorem ipsum dolor sit amet consectetur. Eu aliquam urna purus et non. Nunc sagittis aliquam quam id enim. Metus dictum nulla ultrices nisi id duis. Leo integer risus id a posuere suspendisse augue amet. Sapien sit vulputate et nec sagittis rhoncus proin penatibus. Ut velit condimentum mattis dolor. </p>
                        <p>Blandit morbi et augue ac sit. Sit tincidunt lorem mauris quam commodo tempor placerat montes. Aliquet integer urna mus dui morbi nunc metus lectus ultrices. Enim id mi donec sagittis nisl. Egestas fusce eget interdum nulla tellus. Elementum laoreet habitant lobortis porttitor cursus. Diam dui lorem ac cum duis bibendum. Lobortis nisl gravida in pellentesque. Dolor gravida mauris molestie cras leo at. </p>
                        <p>Faucibus laoreet varius et purus proin neque neque. Eget orci ullamcorper morbi euismod lorem in. Id augue lacus ipsum mauris. Vulputate sollicitudin viverra nisi in sagittis. Leo non faucibus gravida purus laoreet adipiscing fringilla consectetur.</p> 
                    </article>
                </div>
            </section>
        </>
    );
}

export default BlogDetails;