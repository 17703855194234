import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import {urls} from '../../utils/constants'
import "./style.css";

const linkStyle = {
    color: "#101828",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    textDecoration: "none",
};

function ChainsawComponent() {
    const [data, setData] = useState(0);
    // array kosong digunakan supaya request ketika ada perubahan saja 
    useEffect(() => {
        fetchProduct();
    }, []);
    const fetchProduct = async () => {
        axios(urls.API_URL+'chainsaw', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'same-origin',
            }).then(response => {
                console.log(response.data)
                setData(response.data)
            }).catch(function (error) {

            })
    }
    return (
        <>
        <section id="chainsaw">
            <header>
                <div>
                    <img src={process.env.PUBLIC_URL + '/img/assetchainsaw/Chainway.png'} style={{marginBottom: "24px"}}/>
                    <h1>The Industry-Leading Provider of Data Collection Products and Solutions</h1>
                    <p>We offer you a broad portfolio of mobile computers, scanners, RFID readers, tablets, and modules with abundant features and sizes.</p>
                </div>
            </header>
            <h2>Chainway Products</h2>
            <div className="wrapper">
                <div className="row">
                {data && data.map(d => (
                    <div className="product col-md-6">
                        <div className="foto" style={{backgroundImage: `url(${process.env.PUBLIC_URL + d.foto})`}}></div>
                        <h3><Link to={`/product/chainway/${d.id}`} style={linkStyle}>{d.title}</Link></h3>
                        <p>{d.description}</p>
                    </div>
                ))}
                </div>
            </div>

        </section>
        </>
    );
}

export default ChainsawComponent;