import { Link } from "react-router-dom";
import "./style.css";

function CTAComponent(props) {
    return (
        <>
            <section id="cta">
                <div className="container">
                    <div className="wrapper">
                        <p id="cta-typo">{props.tagline}</p>
                        <Link to="/contact" style={{textDecoration: "none", zIndex: 99}}><button className="btn-primary-gfs-cta" >{props.title_button}</button></Link>
                        <div id="cta-map" className="deskt"></div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CTAComponent;

    