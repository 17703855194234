import "./style.css";

function CTASolutions() {
    const redirectToContact = () => {
        window.location.href = "/contact";
    };
    return (
        <>
            <section id="CTASolutions">
                <div className="fl-col">
                    <h2>Ready to fortify your defenses?</h2>
                    <p>Explore our comprehensive security solutions now and take the first step towards a safer digital future.</p>
                    <button className="btn" onClick={redirectToContact}>Contact Us</button>
                </div>
                <img style={{position: 'absolute', top: 0, right: 0, bottom: 0}} src={process.env.PUBLIC_URL + '/img/_Vector_map.svg'} />
            </section>
        </>
    );
}

export default CTASolutions;