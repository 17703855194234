import React, {Component} from "react"
import { IoTSolutions, CTASolutions} from "../../components/"

export default class Project extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});

    }
    render() {
        return (
            <>
                <IoTSolutions/>
                <CTASolutions/>
            </>
        )
    }
}