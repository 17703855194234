import React, {Component} from "react"
import {HeaderComponent, PartnerComponent, ProjectComponent, AboutComponent, ServiceComponent, TestimoniComponent, TechnologyPartnerComponent, CTAComponent } from "../components/"
import {urls} from '../utils/constants' 
import axios from "axios"


export default class Home extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            dataCompanyLogo: [],
            dataBrandNusa: [],
            dataTestimonial: [],
        }
    }
    
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});

        if (document.getElementsByClassName("link-to-gsap").length === 0){
            const gsap_script = document.createElement("script");
            gsap_script.classList.add("link-to-gsap");
            gsap_script.src = urls.GSAP_URL;
            gsap_script.integrity = "sha256-5RGBQKFeXbtHHxnAaBa8+kQXCHi9j+Ct6Awkt6mI2Lo="
            gsap_script.crossOrigin = "anonymous"
            gsap_script.async = false;
            console.log(gsap_script)
            document.body.appendChild(gsap_script);
        }

        if (document.getElementsByClassName("link-to-scrolltrigger").length === 0){
            const scroll_trigger = document.createElement("script");
            scroll_trigger.classList.add("link-to-scrolltrigger");
            scroll_trigger.src = urls.SCROLL_TRIGGER_SCRIPT;
            scroll_trigger.integrity = "sha256-BotszkugxFYh5cKUfrzD9nAakMhBzk8hDJNfAEehnq0="
            scroll_trigger.crossOrigin = "anonymous"
            scroll_trigger.async = false;
            console.log(scroll_trigger)
            document.body.appendChild(scroll_trigger);
        }

        axios(urls.API_URL+'dataCompanyLogo', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
        }).then(response => {
            console.log(response.data)
            this.setState({ dataCompanyLogo: response.data })
        }).catch(function (error) {
            console.log(error)
        })


        axios(urls.API_URL+'dataBrandNusa', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
        }).then(response => {
            console.log(response.data)
            this.setState({ dataBrandNusa: response.data })
        }).catch(function (error) {
            console.log(error)
        })

        axios(urls.API_URL+'dataTestimonial', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'same-origin',
            }).then(response => {
                console.log(response.data)
                this.setState({ dataTestimonial: response.data })
            }).catch(function (error) {
                console.log(error)
            })
    }

    render() {
        const { dataCompanyLogo, dataBrandNusa, dataTestimonial } = this.state;
        return (
            <>
                <HeaderComponent />
                <PartnerComponent data={dataCompanyLogo}/>
                <AboutComponent />
                <ServiceComponent />
                {/* project section */}
                <ProjectComponent />
                {/* end project section */}
                <TestimoniComponent data={dataTestimonial}/>
                <TechnologyPartnerComponent data={dataBrandNusa}/>
                <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
            </>
        )
    }
}