import React, {Component} from "react"

const langs = [
    {
        id: 1,
        name: 'Indonesia',
        flag: 'https://www.countryflags.com/wp-content/uploads/indonesia-flag-png-large.png'
    },
    {
        id: 2,
        name: 'English',
        flag: 'https://www.countryflags.com/wp-content/uploads/united-states-of-america-flag-png-large.png'
    }
]

export default class TermConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 1,
            openSelection: false,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        
    }
    setSelected = (val) => {
        this.setState({ selected: val });
    }
    setOpenSelection = (val) => {
        this.setState(prevState => ({
            openSelection: !prevState.openSelection
        }));
    }
    handleClickOutside = (event) => {
        const { openSelection } = this.state;
        const box = this.boxRef;

        if (box && !box.contains(event.target) && openSelection) {        
            this.setState({ openSelection: false });
        }
    }
    
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
        document.addEventListener('click', this.handleClickOutside);

    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
    render() {
        return (
            <>
            <section className="flex flex-row justify-center" style={{marginRight: '20px',marginLeft: '20px',}}>
                <div style={{width: '800px'}}>
                    <h1 style={{marginBottom: "80px", marginTop: "40px"}} className="text-5xl lg:text-6xl font-semibold text-center text-custom-dark mb-8">Quality and Information Security Policy</h1>

                    <h2 className="text-2xl font-semibold mb-8">Quality Policies</h2>
                    <div style={{marginBottom: "80px"}}>
                        <p className="text-lg font-semibold text-custom-dark mb-8">“NUSA”</p>

                        <p className="text-lg font-semibold text-custom-dark mb-2">N</p>
                        
                        <p style={{borderBottom: "1px solid #eee"}} className="text-lg font-medium text-custom-secondary pb-3 mb-3">Nusa is Customer Oriented Company – Customer Satisfaction is Utmost Goal</p>
                        
                        
                        <p className="text-lg font-semibold text-custom-dark mb-2">U</p>
                        <p style={{borderBottom: "1px solid #eee"}} className="text-lg font-medium text-custom-secondary pb-3 mb-3">Unity, Integrity & Professional Team</p>
                        
                        <p className="text-lg font-semibold text-custom-dark mb-2">S</p>
                        <p style={{borderBottom: "1px solid #eee"}} className="text-lg font-medium text-custom-secondary pb-3 mb-3">Strive for Excellence</p>
                        
                        <p className="text-lg font-semibold text-custom-dark mb-2">A</p>
                        <p style={{borderBottom: "1px solid #eee"}} className="text-lg font-medium text-custom-secondary pb-3">A Law Abiding Company & Community Contributor</p>
                        
                    </div>

                    <h2 className="text-2xl font-semibold mb-8">Information Security Policy</h2>
                    <p className="text-lg font-medium text-custom-secondary mb-8">Management is committed to implementing an Information Security Management System based on ISO/IEC 27001:2022 by applying the principles of confidentiality, integrity, availability, reliability, continuity and compliance by paying attention to the principles effectiveness and efficiency.</p>
                    <p className="text-lg font-semibold text-custom-dark mb-2">Apart from that, management is also committed to:</p>

                    <ul className="list-decimal pl-6">
                        <li className="text-lg font-medium text-custom-secondary mb-2">Ensure that the implementation of the information security management system is in line with PT.  Nusa Network Prakarsa objectives.</li>
                        <li className="text-lg font-medium text-custom-secondary mb-2">Ensure continuous improvement of the Information Security Management System.</li>
                        <li className="text-lg font-medium text-custom-secondary mb-2"> Ensure the achievement of company goals related to information security through regular monitoring of Information Security Goals.</li>
                        <li className="text-lg font-medium text-custom-secondary mb-2"> Implement information security policies and procedures to protect information security and information processing facilities by complying with laws, regulations and policies PT. Nusa Network Prakarsa related to information security.</li>
                        <li className="text-lg font-medium text-custom-secondary mb-2"> Increase awareness of all parties involved in the Information Technology process.</li>
                    </ul>
                </div>
            </section>
            </>
        )
    }
}