import { Link } from "react-router-dom";
import './style.css'

function FooterComponent() {
    const redirectFacebook = () => {
        window.open('https://www.facebook.com/nusanetworkprakarsa/?locale=id_ID', '_blank');
    }
    const redirectInstagram = () => {
        window.open('https://instagram.com/_nusanetworkprakarsa?igshid=MzRlODBiNWFlZA==', '_blank');
    }
    const redirectLinkedin = () => {
        window.open('https://www.linkedin.com/company/nusanetworkprakarsa/', '_blank');
    }
    
    return (
        <>
            <section id="footer" className="deskt">
                <div className="container">
                    <div className="row">
                        <div id="footer-address" className="col-md-6"> 
                            <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="page-misc-error-light" 
                                width="142.5px" className="img-fluid"/>
                            <p id="footer-address-text">
                                Jalan Kamal Raya Outer Ring Road, Mutiara Taman Palem A17 / 29 - 30, Kelurahan Cengkareng Timur, Kecamatan Cengkareng, Jakarta Barat, 11730
                            </p>
                            <img src={process.env.PUBLIC_URL + '/img/icons/iso_certificate.png'} alt="ISO Certificate 9001" style={{paddingTop: "1.75rem"}}/>
                        </div>
                        <div id="footer-links">
                            <div id="footer-link-items">
                                <ul>
                                    <li>Solutions</li>
                                    <li><a href={process.env.PUBLIC_URL + "/solution/SecuritySolution"}>Security Infrastructure</a></li>
                                    <li><a href="/notfound">Professional Services</a></li>
                                    <li><a href="https://www.nada-iot.com" target="_blank" rel="noopener noreferrer">IoT Solution</a></li>
                                    <li><a href={process.env.PUBLIC_URL + "/solution/DataCenterSolution"}>Data Center Infrastructure</a></li>
                                    <li><a href={process.env.PUBLIC_URL + "/solution/SecuritySolution"}>Enterprise Network Infrastructure</a></li>
                                </ul>
                            </div>
                            <div id="footer-link-items">
                                <ul>
                                    <li>Company</li>
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/notfound">Clients</a></li>
                                    <li><a href="/contact">Contact Us</a></li>
                                    <li><a href="/notfound">Blog</a></li>
                                    <li><a href="/notfound">Career</a></li>
                                </ul>
                            </div>
                            <div id="footer-link-items">
                                <ul>
                                    <li>Support</li>
                                    <li><Link to="/term" style={{textDecoration: "none"}}>General Terms</Link></li>
                                    <li><Link to="/policies" style={{textDecoration: "none"}}>Privacy Policy</Link></li>
                                    <li><Link to="/security_policy" style={{textDecoration: "none"}}>Quality and Information 
                                    Security Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer-copyright" className="container">
                    <p>© 2023 Nusa Network Prakarsa. All rights reserved.</p>
                    <div id="media-socials">
                        <img src={process.env.PUBLIC_URL + 'img/social4.svg'} onClick={redirectFacebook} alt="Facebook Icon"/>
                        <img src={process.env.PUBLIC_URL + 'img/social1.svg'} onClick={redirectLinkedin} alt="LinkedIn Icon"/>
                        <img src={process.env.PUBLIC_URL + 'img/social2.svg'} onClick={redirectInstagram} alt="Instagram Icon"/>
                    </div>
                </div>
            </section>
            <section id="footer-mob" className="mob">
                <div className="container">
                    <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="NNP Logo" width="142.5px" className="img-fluid" />
                    <p style={{paddingTop: "1.5rem", paddingBottom: "1.5rem"}}>
                        Jalan Kamal Raya Outer Ring Road, Mutiara Taman Palem A17 / 29 - 30, Kelurahan Cengkareng Timur, Kecamatan Cengkareng, Jakarta Barat, 11730
                    </p>
                    <img src={process.env.PUBLIC_URL + '/img/icons/iso_certificate.png'} alt="ISO Certificate 9001" style={{width: "3.5rem", paddingBottom: "1.5rem"}}/>
                    <ul>
                        <li>Solutions</li>
                        <li><a href="/notfound">Security Infrastructure</a></li>
                        <li><a href="/notfound">Professional Services</a></li>
                        <li><a href="/notfound">IoT Solution</a></li>
                        <li><a href="/notfound">Data Center Infrastructure</a></li>
                        <li><a href="/notfound">Enterprise Network Infrastructure</a></li>
                    </ul>
                    <ul>
                        <li>Company</li>
                        <li><a href="/notfound">About Us</a></li>
                        <li><a href="/notfound">Clients</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                        <li><a href="/notfound">Blog</a></li>
                        <li><a href="/notfound">Career</a></li>
                    </ul>
                    <ul>
                        <li>Support</li>
                        <li><a href="/term">General Terms</a></li>
                        <li><a href="/policies">Privacy Policy</a></li>
                        <li><a href="/security_policy">Quality and Information 
                        Security Policy</a></li>
                    </ul>

                    <div id="footer-copyright-mob" className="container">
                        <div id="media-socials">
                            <img src={process.env.PUBLIC_URL + '/img/social4.svg'} onClick={redirectFacebook}/>
                            <img src={process.env.PUBLIC_URL + '/img/social1.svg'} onClick={redirectLinkedin}/>
                            <img src={process.env.PUBLIC_URL + '/img/social2.svg'} onClick={redirectInstagram}/>
                        </div>
                        <p>© 2023 Nusa Network Prakarsa. <br /> All rights reserved.</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterComponent;

