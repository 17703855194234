import "./style.css"
function CareerDetails() {
    return (
        <>
            <section id="CareerDetails">
                <div className="head">
                    <h1>Penetration Testers</h1>
                </div>
                <article>
                    <div className="row">
                        <div className="col-md-7">
                        <h4>About</h4>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p>
                        <h4>Job Responsibilites</h4>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p>
                        <h4>Requirements</h4>
                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo con</p>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4">
                            <aside>
                                <div className="additional_info">
                                    <span>Industry</span>
                                    <h4>Government</h4>
                                </div>
                                <div className="additional_info">
                                    <span>Timeline</span>
                                    <h4>2019 - Present</h4>
                                </div>
                                <div className="additional_info">
                                    <span>Solutions</span>
                                    <h4>Enterprise Network Infrastructure</h4>
                                </div>
                                <button className="btn btn-primary">Apply</button>
                            </aside>
                        </div>
                    </div>
                </article>
                <div className="modals">
                    <div className="modals_wrapper">
                        <div className="modals_header">
                            <span>Submit Application</span>
                            <img src={process.env.PUBLIC_URL + '/img/mobile/ic_round-close.svg'} id="close-button" alt="close icon"/>
                        </div>
                        <div className="modals_body">
                            <form>
                                <div className="form-group">
                                    <label for="name">Name</label>
                                    <input className="form-control" placeholder="Your Full Name"></input>
                                </div>
                                <div className="form-group">
                                    <label for="name">Email</label>
                                    <input className="form-control" placeholder="you@company.com"></input>
                                </div>
                                <div className="form-group">
                                    <label for="name">Phone Number</label>
                                    <input className="form-control" placeholder="+62 08xx 0000 0000"></input>
                                </div>
                                {/* <div className="form-group"> */}
                                    <label for="name">Resume</label>
                                    <div className="draganddrop"></div>
                                {/* </div> */}
                            </form>
                        </div>
                        <div className="modals_footer">
                            <button className="btn btn-primary">Submit Application</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CareerDetails;