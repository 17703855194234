import './style.css'

function PartnerComponent(props) {
    return (
        <>
            <section id="partner">
                <h2 className="big-title">Trusted by<br className="mob" /> 50+ Companies</h2>
                <div class="slider">
                    <div class="slide-track">
                        {props.data.map(logo => (
                            <div class="slide">
                                <img 
                                    style={{marginRight:'92px'}}
                                    key={logo.id}
                                    alt={`Logo ${logo.id}`}
                                    className="deskt" 
                                    src={process.env.PUBLIC_URL + logo.path} />
                            </div>
                        ))}

                        {props.data.map(logo => (
                            <div class="slide">
                            <img 
                                style={{marginRight:'92px'}}
                                key={logo.id}
                                alt={`Logo ${logo.id}`}
                                className="mob" 
                                src={process.env.PUBLIC_URL + logo.path} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    );
}

export default PartnerComponent;

    