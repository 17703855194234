
function DataCenterSolutions() {
    return (
        <>
            <section id="DataCenterSolution">
                <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/dots.svg'})`}}>
                    <hgroup>
                        <span className="span-blue">Data Center Solution</span>
                        <h1>Uncover unrivaled security and dependability. </h1>
                        <span className="span-dark">We ensuring that your data is always safe and accessible.</span>
                    </hgroup>
                </div>
                
                <div className="DataCenterSolutionBG"> 
                    <img src={process.env.PUBLIC_URL + '/img/ic_elipse.svg'}/>
                </div>
            </section>
        </>
    );
}

export default DataCenterSolutions;