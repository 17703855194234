import React, {Component} from "react"
import {DataCenterSolutions, GlipGlop, CTAComponent} from "../../components/"

export default class DataCenterSolution extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        const contents = [
            {
                'title': 'Server & Storage',
                'description': 'Keep your business efficient and sustainable in a simple way with a scalable and reliable server and storage solution for your high-traffic productivity.',
                'background_path': '/img/glipglop2_rectangle/Rectangle_9021.png',
                'icon_path': '/img/glipglop2_icon/uil_servers.svg',
            },
            {
                'title': 'Personal Computer',
                'description': 'Elevate your business operational efficiency and competitiveness with a high-valuable multi-purpose microcomputer whose size, and capabilities make it viable for individual use.',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9022.png',
                'icon_path': '/img/glipglop2_icon/uil_desktop.svg',
            },
            {
                'title': 'Hyper Converged Infrastructure',
                'description': 'Offers companies a modern solution that is simpler, smarter, smaller, and more efficient. Level up your storage and boost up your business.',
                'background_path': '/img/glipglop2_rectangle/Rectangle_9021.png',
                'icon_path': '/img/glipglop2_icon/Frame_7026.svg',
            },
            {
                'title': 'Cloud Infrastructure',
                'description': 'Give you the best solutions with hardware and software needed to enable cloud computing. Bring your business protection to the next level.',
                'background_path': '/img/glipglop2_rectangle/Rectangle_9023.png',
                'icon_path': '/img/glipglop2_icon/uil_data-sharing.svg',
            },
            {
                'title': 'Data Recovery',
                'description': 'The best option and solutions to double up your business recovery and security in a fast and easy way. Keep you moving without a hitch.',
                'background_path': '/img/glipglop2_rectangle/Rectangle_9026.png',
                'icon_path': '/img/glipglop2_icon/uil_cloud-redo.svg',
            },
            {
                'title': 'Backup & Replication',
                'description': 'Run your business with care and without worry! Do some mitigation plans to back up your servers and data with simpler methods. We protect you relentlessly.',
                'background_path': '/img/glipglop2_rectangle/Rectangle_9025.png',
                'icon_path': '/img/glipglop2_icon/uil_cloud-database-tree.svg',
            },
        ]
        return (
            <>
                <DataCenterSolutions/>
                {/* <CompanyList/> */}
                <GlipGlop 
                head_glip_h3='Nusa Data Center Solution'
                head_glip_p='We provide 24/7 support and monitoring for our data center solutions, ensuring that your data is always accessible and that any issues are addressed immediately'
                contents={contents}
                />
                <CTAComponent tagline="Ready to secure your business?" title_button="Contact Us"/>
                {/* <CTASolutions/> */}
            </>
        )
    }
}