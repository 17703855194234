import React, {Component} from "react"
import {Error404Component} from "../components"

export default class Error404 extends Component {
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        return (
            <Error404Component/>
        )
    }
}
