import React, {Component} from "react"
import {AboutUsDetailsComponent, CTAComponent} from "../components/"
import {urls} from '../utils/constants'
import axios from "axios"

export default class About extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataBrandNusa: [],
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
        axios(urls.API_URL+'dataBrandNusa', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'same-origin',
            }).then(response => {
                console.log(response.data)
                this.setState({ dataBrandNusa: response.data })
            }).catch(function (error) {
                console.log(error)
            })
    }
    render() {
        const {dataBrandNusa } = this.state;

        return (
            <>
                <AboutUsDetailsComponent dataBrandNusa={dataBrandNusa}/>
                <CTAComponent tagline="Be part of our company's journey and join us today." title_button="See Open Roles"/>
            </>
        )
    }
}