import React, {useEffect, useRef} from "react"
import "./style.css";
import 'flickity/css/flickity.css'
import Flickity from 'flickity';

function TestimoniComponent(props) {
    const carousel = useRef(null);

    useEffect(() => {
        const flkty = new Flickity(carousel.current, {
            lazyLoad: true,
            wrapAround: true,
            autoPlay: true,   
            prevNextButtons: false,
            pageDots: false,
        });
        // pakai ini untuk liat slide yang aktif flkty.selectedIndex
        const customDots = document.querySelectorAll('.custom-dot');
            customDots.forEach((dot, index) => {
            dot.addEventListener('click', () => {
                flkty.select(index);
            });
        });

        return () => {
            customDots.forEach((dot, index) => {
                dot.removeEventListener('click', () => {
                    flkty.select(index);
                });
            });
        };

    }, []);

    return (
        <>
            <section id="testimoni">
                <div className="container">
                    <h1>Our Clients Testimonial</h1>
                    <div className="col-md-6">
                        <p>See what our clients have to say about their journey with us.</p>
                    </div>
                </div>
                
                <div ref={carousel} className="main-carousel">
                    <div className="carousel-cell">
                        <p className="comment">
                        Overall, everything has been good from the salesperson and other teams, including other services such as SOC, Managed Services, and Application Platform.
                        </p>
                        <div className="detail_writer">
                            <div className="companyLogo" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/testimoni-icon/mind.png'})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                            <div className="writer_flex">
                                <span className="writer_name">Widiya Kumoro</span>
                                <span className="writer_company">Mining Industry Indonesia</span>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell">
                        <p className="comment">
                        Nusa a company that offers professional IT consultancy services and has a good innovation
                        </p>
                        <div className="detail_writer">
                            <div className="companyLogo" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/testimoni-icon/itm.png'})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                            <div className="writer_flex">
                                <span className="writer_name">Mahesta Anandito</span>
                                <span className="writer_company">PT Indo Tambangraya Megah Tbk</span>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell">
                        <p className="comment">
                        No more glitches or hiccups. Nusa as IT vendor is working seamlessly.
                        </p>
                        <div className="detail_writer">
                            <div className="companyLogo" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/testimoni-icon/darmahenwa.png'})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                            <div className="writer_flex">
                                <span className="writer_name">Teuku Nazwar R</span>
                                <span className="writer_company">PT. Darma Henwa, Tbk</span>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell">
                        <p className="comment">
                        Thank you, Nusa team, for always providing the best services and working together solidly between teams. Kudos to you all!
                        </p>
                        <div className="detail_writer">
                            <div className="companyLogo" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/testimoni-icon/tunas.png'})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                            <div className="writer_flex">
                                <span className="writer_name">Muhammad Alaydrus</span>
                                <span className="writer_company">PT. Tunas Ridean Tbk</span>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell">
                        <p className="comment">
                        Thank you Nusa team, for providing good services.
                        </p>
                        <div className="detail_writer">
                            <div className="companyLogo" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/testimoni-icon/kai.png'})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                            <div className="writer_flex">
                                <span className="writer_name">Atim</span>
                                <span className="writer_company">PT Kereta Api Indonesia (Persero)</span>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell">
                        <p className="comment">
                        Thank you, Nusa, for your dedication in helping to handle network projects at our company and getting good project results.

                        </p>
                        <div className="detail_writer">
                            <div className="companyLogo" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/testimoni-icon/bhimasena.png'})`,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                            <div className="writer_flex">
                                <span className="writer_name">Maihandra</span>
                                <span className="writer_company">Bhimasena Power Indonesia</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="custom-dots">
                    <div className="custom-dot"></div>
                    <div className="custom-dot"></div>
                    <div className="custom-dot"></div>
                    <div className="custom-dot"></div>
                    <div className="custom-dot"></div>
                    <div className="custom-dot"></div>
                </div>
            </section>
        </>
    );
}

export default TestimoniComponent;