import "./style.css";

function NetworkSolutions() {
    return (
        <>
            <section id="NetworkSolution">
                <hgroup>
                    <span className="span-blue">Enterprise Network Solutions</span>
                    <h1 className="w-58">Reliable and Efficient Network Solutions for Your Business</h1>
                    <span className="span-dark">Unlock the Power of Seamless Connectivity and Unparalleled Efficiency</span>
                    <div className="decor_grid"></div>
                </hgroup>
                <div className="mvp-img pt-96"></div>
            </section>
        </>
    );
}

export default NetworkSolutions;