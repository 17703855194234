import React, {Component} from "react"
import {ProjectDetails3, CTAComponent} from "../components"
import {urls} from '../utils/constants'
import axios from "axios"



export default class ProjectDetailsPage3 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});

    }
    render() {
        return (
            <> 
                <ProjectDetails3/>
                <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
            </>
        )
    }
}