import React, {Component} from "react"
import {NetworkSolutions, GlipGlop, CTAComponent} from "../../components/"

export default class NetworkSolution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        const contents = [
            {
                'title': 'Enterprise Network',
                'description': 'Enable efficient and secure information flow within the organization, supporting the day-to-day operations and facilitating collaboration among employees.',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9021.png',
                'icon_path': '/img/glipglop1_icon/ic_round-wifi.svg',
            },
            {
                'title': 'Collaboration',
                'description': 'Connect from anywhere, at any time. Say goodbye to the hassle of scheduling in-person meetings and dealing with travel time.',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9022.png',
                'icon_path': '/img/glipglop1_icon/Frame_7026.svg',
            },
            {
                'title': 'Data Center & Data Protection',
                'description': 'Enjoy peace of mind knowing that your data is secure and protected from any potential threats',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9023.png',
                'icon_path': '/img/glipglop1_icon/ic_round-security.svg',
            },
            {
                'title': 'SD-Wan Solution',
                'description': 'Say goodbye to the limitations of rigid hardware configurations and embrace software-defined networking that adapts to your business needs.',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9024.png',
                'icon_path': '/img/glipglop1_icon/uil_cloud-computing.svg',
            },
            {
                'title': 'Wi-Fi Solutions',
                'description': 'Experience lightning-fast speeds and expansive coverage with our latest Wi-Fi standards.',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9025.png',
                'icon_path': '/img/glipglop1_icon/uil_wifi-router.svg',
            },
            {
                'title': 'Cloud Network',
                'description': 'Unleashing the full potential of your network. Embrace the power of cloud technology and explore new horizons in efficiency, collaboration, and growth.',
                'background_path': '/img/glipglop1_rectangle/Rectangle_9026.png',
                'icon_path': '/img/glipglop1_icon/uil_cloud-wifi.svg',
            },
        ]
        return (
            <>
                <NetworkSolutions/>
                {/* <CompanyList/> */}
                <GlipGlop
                head_glip_h3='Nusa Network Enterprise'
                head_glip_p='Our solutions are designed to streamline your network operations, enhance productivity, and enable seamless communication and collaboration across your organization.'
                contents={contents}
                />
                {/* <CTASolutions/>  */}
                <CTAComponent tagline="Ready to secure your business?" title_button="Contact Us"/>
            </>
        )
    }
}