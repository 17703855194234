import React, {Component} from "react"
import {Career, CTASolutions} from "../components"

export default class CareerPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        return (
            <>
                <Career/>
                <CTASolutions/>
            </>
        )
    }
}