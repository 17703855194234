import { Link } from "react-router-dom";
import "./style.css"

function Error404() {
    const goHome = () => {
        window.location.href = "/";
    }
    return (
        <>
            <section id="error404">
                <div className="container">
                    <h1>We can’t find the page you’re looking for</h1>            
                    <Link to="/" style={{textDecoration: "none"}}><button className="btn-primary-gfs">Back to Home</button></Link>
                </div>
                <h1 className="lg">404</h1>
            </section>
        </>
    )
}
export default Error404;