import styled, { css } from "styled-components";

const ButtonStyled = styled.button`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 12px 32px 12px 32px;
    gap: 8px;
    height: 44px;
    border-radius: 8px;
    ${(props) => setVariants(props)};
`
const setVariants = (props) => {

    if (props.variant === "primary") {
        return css`
            border: 1px solid #3B82F6;
            color: #fff;
            background: #3B82F6 !important;
        `

    } else if (props.variant === "light") {
        return css`
            background: #FFFFFF;
            color: #3B82F6;
            border-color: #FFFFFF;
        `
    }
}
function Button(props) {
    return (
        <>
            <ButtonStyled variant={props.variant}>
                {props.children}
            </ButtonStyled>
        </>
    )
}
export default Button;