import { Link } from "react-router-dom";
import "./style.css";

function GlipGlop(props) {
    return (
        <>
            <section id="GlipGlop" className="dekst">
                <div className="head-Glip">
                    <h3>{props.head_glip_h3}</h3>
                    <p>{props.head_glip_p}</p>
                </div>
                <div className="body-Glip">
                    <div className="description">
                        <h4>{props.contents[0].title}</h4>
                        <p>{props.contents[0].description}</p> 
                        <Link to="notfound">Learn More</Link>
                    </div>
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[0].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[0].icon_path} />
                    </div>
                </div>
                <div className="body-Glop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[1].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[1].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[1].title}</h4>
                        <p>{props.contents[1].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-Glip">
                    <div className="description">
                        <h4>{props.contents[2].title}</h4>
                        <p>{props.contents[2].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[2].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[2].icon_path} />
                    </div>
                </div>
                <div className="body-Glop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[3].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[3].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[3].title}</h4>
                        <p>{props.contents[3].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-Glip">
                    <div className="description">
                        <h4>{props.contents[4].title}</h4>
                        <p>{props.contents[4].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[4].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[4].icon_path} />
                    </div>
                </div>
                <div className="body-Glop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[5].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[5].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[5].title}</h4>
                        <p>{props.contents[5].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
            </section>

            <section id="GlipGlop-mob" className="mob">
                <div className="head-Glip">
                    <h3>{props.head_glip_h3}</h3>
                    <p>{props.head_glip_p}</p>
                </div>
                <div className="body-glipglop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[0].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[0].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[0].title}</h4>
                        <p>{props.contents[0].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-glipglop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[1].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[1].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[1].title}</h4>
                        <p>{props.contents[1].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-glipglop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[2].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[2].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[2].title}</h4>
                        <p>{props.contents[2].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-glipglop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[3].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[3].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[3].title}</h4>
                        <p>{props.contents[3].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-glipglop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[4].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[4].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[4].title}</h4>
                        <p>{props.contents[4].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
                <div className="body-glipglop">
                    <div className="illustration" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',backgroundImage: `url(${process.env.PUBLIC_URL + props.contents[5].background_path})`, backgroundSize: 'cover'}}>
                        <img src={process.env.PUBLIC_URL + props.contents[5].icon_path} />
                    </div>
                    <div className="description">
                        <h4>{props.contents[5].title}</h4>
                        <p>{props.contents[5].description}</p>
                        <Link to="notfound">Learn More</Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default GlipGlop;