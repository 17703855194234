import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

function ProjectDetails3() {
    return(
        <>
            <div className="mx-custom-112 lg:block hidden">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">Leveling Up Learning and Security, Politeknik STIA LAN Jakarta’s Digital Transformation</h1>
            </div>
            <div className="mx-4 lg:hidden sm:block">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">Leveling Up Learning and Security, Politeknik STIA LAN Jakarta’s Digital Transformation</h1>
            </div>
            <div className="w-full img-hero-politeknik lg:h-custom-516 h-custom-250"></div>
            <div className="lg:mx-custom-112 px-4">
                <div className="flex lg:flex-row flex-col-reverse">
                    <div className="basis-3/4 lg:py-24 sm:pt-12 lg:px-custom-104">
                        <h3 className="text-custom-32 font-semibold mb-6">Background</h3>
                        <p className="text-lg text-custom-paragraph">Politeknik STIA LAN Jakarta, a prominent educational institution committed to fostering the next generation of public administration professionals, faced a growing challenge: safeguarding its digital environment against evolving cyber threats. Ineffective threat detection, scalability limitations, and complex management hampered overall security posture and posed potential risks to valuable data, user privacy, and operational continuity. Their existing antivirus solution struggled to keep pace with the increasing sophisticated of malware and the institution’s expanding digital footprint. Recognizing the need for a more robust and reliable antivirus solution, Politeknik STIA LAN partnered with Nusa Network Prakarsa to explore and implement a comprehensive security solution.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Challenge</h3>
                        <p className="text-lg text-custom-paragraph">Integrating Sophos Antivirus with Politeknik STIA LAN Jakarta’s existing network infrastructure and endpoints required careful planning and execution to ensure compatibility and minimize disruptions. The existing solution lacked advanced capabilities to counter sophisticated malware attacks, including zero-day exploits and ransomware, leaving the institution vulnerable. Educational institutions often face resource constraints, including limited budgets and IT personnel. Deploying and managing a comprehensive antivirus solution like Sophos Antivirus within these constraints posed a challenge.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Solution</h3>

                        <p className="text-lg text-custom-paragraph">Nusa Network Prakarsa conducted a comprehensive assessment of Politeknik STIA LAN Jakarta’s network and endpoint to develop a customized endpoint policies tailored to the institute’s specific needs and security objectives. These policies encompassed parameters such as malware scanning schedules, firewall configurations, and web filtering rules to optimize protection while minimizing system impact. Leveraging automated deployment tools and centralized management platforms, Nusa Network Prakarsa streamlined the distribution and installation of Sophos Antivirus across Politeknik STIA LAN Jakarta’s network. This approach enabled swift and efficient deployment while providing real-time visibility and control over the installation process.</p><br/><br/>

                        <p className="text-lg text-custom-paragraph">Implementing Sophos Antivirus on several endpoint devices which will be protected and monitored by Cloud Sophos Central. Recognizing the evolving threats and complex management challenges, Nusa Network Prakarsa implemented Sophos Antivirus with powerful features: advanced threat detection, granular device and user-based security policies, efficient deployment through manage installer, and centralized management. This combination effectively reduced cyberattack risk, streamlined IT administration, ensured future scalability, and standardized protection across all devices, ultimately fortifying Politeknik STIA LAN Jakarta’s digital environment.</p>

                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Conclusion</h3>
                        <p className="text-lg text-custom-paragraph">Through strategic collaboration with Nusa Network Prakarsa, Politeknik STIA LAN Jakarta successfully implemented Sophos antivirus with customized endpoint policies and efficient installer management. By addressing the unique challenges faced by the educational institute, we helped fortify Politeknik STIA LAN Jakarta’s cybersecurity defenses and create a secure learning environment for its stakeholders. This partnership underscores the importance of tailored solutions and proactive measures in mitigating cyber threats and safeguarding sensitive information in educational institutions. As a result, Politeknik STIA LAN Jakarta is better equipped to navigate the evolving cybersecurity landscape and uphold its commitment to excellence in education and information security.</p>

                    </div>
                    <div className="basis-1/4 relative lg:pt-24 py-12">
                        <div className="lg:w-72 w-full border bg-custom-card p-6 rounded-2xl">
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Industry</span>
                                <span className="text-xl font-semibold">Education</span>
                            </div>
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Timeline</span>
                                <span className="text-xl font-semibold">2023</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base text-custom-paragraph">Solutions</span>
                                <span className="text-xl font-semibold">Professional Services</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='py-16'>
                    <h3 className="lg:text-4xl text-2xl font-bold mb-10">Read more our customer stories</h3>

                    <div className="flex lg:flex-row flex-col lg:space-x-4 lg:space-y-0 space-y-4 lg:mb-24">
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard3.png'})`}}>
                            <Link to="/project/Tereos-FKS-Indonesia">
                                <div className="flex flex-col justify-between h-full">
                                    <img className="" style={{height: '30px', width: '150px'}} src={process.env.PUBLIC_URL + '/img/blog/tereos.png'} />
                                    <h1 className="lg:text-3xl text-base font-semibold text-white">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard4.png'})`}}>
                            <Link to="/project/Saka-Farma-Laboratories">
                                <div className="flex flex-col justify-between h-full">
                                    <img className="" style={{height: '40px', width: '100px'}} src={process.env.PUBLIC_URL + '/img/blog/kalbe.png'} />
                                    <h1 className="lg:text-3xl text-base font-semibold text-white">From Bottlenecks to Breakthrough, PT. Saka Farma Laboratories Uncorks Efficiency with HPE ProLiant Servers</h1>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProjectDetails3