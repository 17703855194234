import React, {useState, useRef, useEffect} from "react"
import { Link } from "react-router-dom";
import "./style.css";
import "./script.js";
import Button from "../ui/Button"


function NavbarComponent() { 
    const [toggleNav, setToggleNav] = useState(false);
    const [activeMenu, setActiveMenu] = useState('menu1')
    const [hideSubmenuSolution, setHideSubmenuSolution] = useState('d-none');
    const [hideSubmenuProduct, setHideSubmenuProduct] = useState('');
    const [isOpenMenuMobile, setOpenMenuMobile] = useState(false)
    const [activeSolutions, setActiveSolutions] = useState(false)

    // open-button

    useEffect(() => {
        function handleClickOutside(event) {
            
            let el_parent = document.getElementById('dropdown-navigation')
            let el_parent_mob = document.getElementById('dropdown-navigation-mobile')
            if (el_parent !== null) {
                if (!el_parent.contains(event.target) ) {
                    if (event.target.id !== "link-solutions") {
                        setToggleNav(false)
                        
                    }
                }
            } 
            if (el_parent_mob !== null) {
                if (!el_parent_mob.contains(event.target) ) {
                    setOpenMenuMobile(false)
                    document.querySelector('nav.navbar-expand-md').style.display = 'block'
                }
            }
        }
        // Tambahkan event listener saat komponen dimount
        document.addEventListener("mousedown", handleClickOutside);

        // Hapus event listener saat komponen di-unmount
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
        
    }, []);

    const handleOpenSolutions = (event) => {
        if (activeSolutions) {
            setActiveSolutions(false)
            // document.getElementById('prevBtn').classList.add('d-none')
            // document.querySelector('.logo-nnp-mob').classList.add('d-none')
            
        }else {
            setActiveSolutions(true)
            // document.querySelector('.logo-nnp-mob').classList.remove('d-none')
            document.getElementById('logo-nnp-mob').classList.add('d-none')
            document.getElementById('prevBtn').classList.remove('d-none')
            document.getElementById('txt-solutions').classList.remove('d-none')

            document.getElementById('menu-solutions').classList.add('d-none')
            document.getElementById('menu-product').classList.add('d-none')
            document.getElementById('btn-about-us').classList.add('d-none')
            document.getElementById('btn-contact-us').classList.add('d-none')
            document.getElementById('btn-support').classList.add('d-none')

            document.getElementById('menu-data-center-infrastructure').classList.remove('d-none')
            document.getElementById('menu-network-infrastructure').classList.remove('d-none')
            
            
        }
    }
    const handlePrevBtn = (event) => {
        setActiveSolutions(false)
        document.getElementById('logo-nnp-mob').classList.remove('d-none')
        document.getElementById('prevBtn').classList.add('d-none')
        document.getElementById('txt-solutions').classList.add('d-none')

        document.getElementById('menu-solutions').classList.remove('d-none')
        document.getElementById('menu-product').classList.remove('d-none')
        document.getElementById('btn-about-us').classList.remove('d-none')
        document.getElementById('btn-contact-us').classList.remove('d-none')
        document.getElementById('btn-support').classList.remove('d-none')

        document.getElementById('menu-data-center-infrastructure').classList.add('d-none')
        document.getElementById('menu-network-infrastructure').classList.add('d-none')        
        
    }

    const ToggleClass = (menu) => {
        setActiveMenu(menu); 

        if (menu === 'menu1') {
            setHideSubmenuSolution('d-none');
            setHideSubmenuProduct('');
        } else {
            setHideSubmenuProduct('d-none');
            setHideSubmenuSolution('');
        }
    };
    const redirectToContact = () => {
        window.location.href = "/contact";
    };
    const redirectToHome = () => {
        window.location.href = "/";
    }
    const redirectToNetworkSolution = () => {
        window.location.href = "/solution/NetworkSolution";
    };
    const redirectToSecuritySolution = () => {
        window.location.href = "/solution/SecuritySolution";
    };
    const redirectToDataCenterSolution = () => {
        window.location.href = "/solution/DataCenterSolution";
    };
    const redirectToChainsaw = () => {
        window.location.href = "/product/chainway";
    };
    const handleOpenMenuMobile = () => {
        setOpenMenuMobile(!isOpenMenuMobile)
        if (isOpenMenuMobile == true) {
            document.querySelector('nav.navbar-expand-md').style.display = 'block'
        } else {
            document.querySelector('nav.navbar-expand-md').style.display = 'none'
        }
        
    }
    const redirectSupport = () => {
        window.open('http://ticket.nusanetwork.com:8069/helpdesk/', '_blank');
    }
    const redirectToAbout = () => {
        window.location.href = "/about";
    }



    return (
        <>
        <nav className="navbar-expand-md fixed-top bg-body-light navbar-custom-gfs" style={{zIndex: "999"}}>
            <div className="container" style={{display: "flex", justifyContent: "space-between",  width: "100%"}}>
            <Link to="/">
            <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="page-misc-error-light" width="100px" className="img-fluid logo-nnp"/>
            </Link>
                    <div className="navbar-toggler" style={{border: "0", paddingRight: "0", }}>
                        <img src={process.env.PUBLIC_URL + '/img/mobile/menu.svg'} className="mob" id="open-button" onClick={handleOpenMenuMobile} />
                        <img src={process.env.PUBLIC_URL + '/img/mobile/ic_round-close.svg'} className="mob d-none" id="close-button" />
                    </div>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" >
                        <li className="nav-item dropdown">
                            <div className="nav-link mt-1" id="link-solutions" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setToggleNav(!toggleNav)} >
                                Solutions
                            </div>
                        </li>
                        
                        <li className="nav-item mt-1">
                            <Link to="/about" className="nav-link" style={{textDecoration: "none"}}>
                                About Us
                            </Link>
                        </li>
                        <li className="nav-item mt-1">
                            <a className="nav-link" href="/project">
                                Projects
                            </a>
                        </li>
                    </ul>
                    <div className="nav-links-end">
                        <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                            <li className="nav-item" style={{marginRight: "24px", paddingTop: "3px"}}>
                                <a className="nav-link" href="http://ticket.nusanetwork.com:8069/helpdesk/" target="_blank">Support</a>
                            </li>
                            <li className="nav-item" style={{marginRight: "0px"}}>
                                <Link to="/contact" style={{textDecoration: "none"}}>
                                    <Button variant="primary">Contact Us</Button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
        
        {toggleNav && (
        <div id="dropdown-navigation">
            <div className="dropdown-menu-nav">
                <ul>
                    <li className={activeMenu == 'menu1' ? "active_nav" : ""} onClick={() => ToggleClass('menu1')}>
                        <span>Solutions</span>
                        <p>We provide truly prominent IT Solutions</p>
                    </li>
                    <li className={activeMenu == 'menu2' ? "active_nav" : ""} onClick={() => ToggleClass('menu2')}>
                        <span>Products</span>
                        <p>Reliable and efficient products for your business</p>
                    </li>
                </ul>
            </div>
            <div className="dropdown-submenu-nav">
                <ul className="">
                    <li className={hideSubmenuProduct}>
                        <Link to="/solution/NetworkSolution" style={{textDecoration: "none"}}>
                        <span className="flex"><img src={process.env.PUBLIC_URL + '/img/ic_round-wifi.svg'} style={{marginRight: "16px"}}/>Network Infrastructure</span>
                        <p style={{marginLeft: "40px"}}>Provide you with the support and tools you need</p>
                        </Link>
                    </li>
                    <li className={hideSubmenuProduct}>
                        <Link to="/solution/SecuritySolution" style={{textDecoration: "none"}}>
                        <span className="flex"><img src={process.env.PUBLIC_URL + '/img/ic_round-security.svg'} style={{marginRight: "16px"}}/>Security Infrastructure</span>
                        <p style={{marginLeft: "40px"}}>Help you to defend your business against malicious threats</p>
                        </Link>
                    </li>
                    <li className={hideSubmenuProduct}>
                        <Link to="/solution/DataCenterSolution" style={{textDecoration: "none"}}>
                        <span className="flex"><img src={process.env.PUBLIC_URL + '/img/ic_round-storage.svg'} style={{marginRight: "16px"}}/>Data Center Infrastructure</span>
                        <p style={{marginLeft: "40px"}}>We ensuring that your data is always safe and accessible.</p>
                        </Link>
                    </li>
                    <li className={hideSubmenuSolution}>
                        <Link to="/product/chainway" style={{textDecoration: "none"}}>
                        <span className="flex"><img src={process.env.PUBLIC_URL + '/img/ic_round-qr-code-scanner.svg'} style={{marginRight: "16px"}}/>Chainway</span>
                        <p style={{marginLeft: "40px"}}>Solutions for RFID, barcode and biometrics technologies</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
        )}

        <div id="wrapper-dropdown-mobile" className={`mob ${isOpenMenuMobile ? '' : 'd-none'}`}>
            <div id="dropdown-navigation-mobile">
                <div id="nav-header-mobile-id" style={{borderTop: 0}}>
                    <img src={process.env.PUBLIC_URL + '/img/icons/icon_prev.png'} style={{alignSelf: "start"}} onClick={handlePrevBtn} className="d-none" id="prevBtn"/>
                    <img src={process.env.PUBLIC_URL + '/img/logo.svg'} alt="page-misc-error-light" width="100px" className="img-fluid" id="logo-nnp-mob" onClick={redirectToHome}/>
                    <span className="d-none" id="txt-solutions" style={{fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px"}}>Solutions</span>
                    <img src={process.env.PUBLIC_URL + '/img/icons/icon_times.png'} style={{alignSelf: "end"}} onClick={handleOpenMenuMobile}/>
                </div>
                <div id="menu-solutions" onClick={handleOpenSolutions}>
                    <div style={{display: "flex", flexDirection: "column", padding: "0", border: 0}}>
                        <h6 style={{alignSelf: "start"}}>Solutions</h6>
                        <p style={{marginBottom: "0"}}>We provide truly prominent IT Solutions</p>
                    </div>
                </div>
                <div id="menu-product">
                    <div style={{display: "flex", flexDirection: "column", padding: "0", border: 0}}>
                        <h6 style={{alignSelf: "start"}}>Product</h6>
                        <p style={{marginBottom: "0"}}>Reliable and efficient products for your business</p>
                    </div>
                </div>
                <div onClick={redirectToAbout} id="btn-about-us">
                    <div style={{display: "flex", flexDirection: "column", padding: "0", border: 0}}>
                        <h6 style={{alignSelf: "start"}}>About Us</h6>
                    </div>
                </div>
                <div style={{border: 0, paddingBottom: 0, paddingTop: "1.5rem"}} id="btn-contact-us">
                    <button className="btn btn-primary w-100" style={{marginTop: 0}} onClick={redirectToContact}>Contact Us</button>
                </div>
                <div style={{paddingTop: "1rem", paddingBottom: "1.5rem", border: 0}} id="btn-support">
                    <button className="btn btn-light w-100 font-weight-bold" style={{marginBottom: 0}} onClick={redirectSupport}>Support</button>
                </div>
                <div className="d-none" id="menu-network-infrastructure" onClick={redirectToNetworkSolution}>
                    <img src={process.env.PUBLIC_URL + '/img/ic_round-wifi.svg'} style={{alignSelf: "start", marginRight: "16px"}}/>
                    <div style={{display: "flex", flexDirection: "column", padding: "0", border: 0}}>
                        <h6 style={{alignSelf: "start"}}>Network Infrastructure</h6>
                        <p style={{marginBottom: "0"}}>Provide you with the support and tools you need</p>
                    </div>
                </div>
                <div className="d-none" id="menu-data-center-infrastructure" onClick={redirectToDataCenterSolution}>
                    <img src={process.env.PUBLIC_URL + '/img/ic_round-storage.svg'} style={{alignSelf: "start", marginRight: "16px"}}/>
                    <div style={{display: "flex", flexDirection: "column", padding: "0", border: 0}}>
                        <h6 style={{alignSelf: "start"}}>Data Center Infrastructure</h6>
                        <p style={{marginBottom: "0"}}>We ensuring that your data is always safe and accessible.</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default NavbarComponent;
