function HeaderComponent() {
    const redirectToContact = () => {
        window.location.href = "/contact";
    };
    
    return (
        <>
            <div id="header">
                <section id="typography">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <h1 style={{fontWeight: 600}}>We provide truly<br /> prominent <br className="mob" /><span style={{color: "#3B82F6"}}>IT Solutions</span></h1>
                            </div>
                            <div className="col-md-4" id="typography-tag1">
                                <p id="typography-tag2">Highly Tailored IT Design,<br /> Management & Support Services.</p>
                                <button className="btn btn-primary-gfs mob" onClick={redirectToContact}>Contact Us</button>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="banner">
                </section>
            </div>
        </>
    );
}

export default HeaderComponent;

    