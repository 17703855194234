import "./style.css";

function ContactInformationComponent() {
    return (
        <>
            <section id="contact_information" className="deskt">
                <h1>Or just contact us <br/>in other ways</h1>
                <div className="collection-cards">
                    <div className="card" style={{marginTop: 0}}>
                        <div className="card-body">
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1495-1.svg'} />
                            <div>
                                <h2>Email to Our Sales</h2>
                                <p>Tell your need with our sales</p>
                                <a href="mailto:sales@nusanetwork.com">sales@nusanetwork.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1495-2.svg'} />
                            <div>
                                <h2>Email to Nusa Support</h2>
                                <p>Let Nusa solve your issues</p>
                                <a href="mailto:support@nusanetwork.com">support@nusanetwork.com</a>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1495-3.svg'} />
                            <div>
                                <h2>Our Location</h2>
                                <p>Visit our main Office</p>
                                <a href="https://www.google.com/maps/?q=-6.137374155206131,106.73200246927368" target="_blank">View on Google Maps</a>
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{marginBottom: "4rem"}}>
                        <div className="card-body">
                            <img src={process.env.PUBLIC_URL + '/img/Frame-1495-4.svg'} />
                            <div>
                                <h2>Call Us</h2>
                                <p>We're available 24/7</p>
                                <a href="tel://+622154353007">+62 21 5435 3007</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactInformationComponent;

