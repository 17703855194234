import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

function ProjectDetails4() {
    return(
        <>
            <div className="mx-custom-112 lg:block hidden">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">From Bottlenecks to Breakthrough, PT. Saka Farma Laboratories Uncorks Efficiency with HPE ProLiant Servers</h1>
            </div>
            <div className="mx-4 lg:hidden sm:block">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">From Bottlenecks to Breakthrough, PT. Saka Farma Laboratories Uncorks Efficiency with HPE ProLiant Servers</h1>
            </div>
            <div className="w-full img-hero-saka lg:h-custom-516 h-custom-250"></div>
            <div className="lg:mx-custom-112 px-4">
                <div className="flex lg:flex-row flex-col-reverse">
                    <div className="basis-3/4 lg:pt-24 sm:pt-12 lg:px-custom-104">
                        <h3 className="text-custom-32 font-semibold mb-6">Background</h3>
                        <p className="text-lg text-custom-paragraph">PT. Saka Farma Laboratories, a leading pharmaceutical company, recognized the critical importance of robust IT infrastructure to support its operations. In line with their commitment to innovation and efficiency, PT. Saka Farma Laboratories sought to upgrade its server infrastructure by implementing HPE ProLiant DL160 Gen 10 servers. With a commitment to innovation and efficiency, PT. Saka Farma Laboratories embarked on project upgrade its server infrastructure. In collaboration with Nusa Network Prakarsa, this upgrade aimed to enhanced performance, scalability, and reliability to meet the increasing demands of their digital operations.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Challenge</h3>
                        <p className="text-lg text-custom-paragraph">Integrating the new HPE ProLiant DL160 Gen 10 servers with PT. Saka Farma Laboratories’ existing IT infrastructure posed a challenge. Compatibility issues and seamless integration with legacy systems needed to be addressed to ensure smooth operation and data migration. Ensuring data security and compliance in a growing virtualized environment required a robust and reliable platform. Deploying virtualization on the HPE ProLiant DL 160 Gen 10 servers required specialized technical knowledge and expertise, as configuring the virtualization environment while ensuring optimal performance and resource allocation proved to be a complex task.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Solution</h3>

                        <p className="text-lg text-custom-paragraph">Leveraging their technical expertise, Nusa Network Prakarsa deployed and configured the HPE ProLiant DL160 Gen 10 servers. We ensured seamless integration with existing systems, optimized resource allocation, and implemented best practices for performance and reliability. Nusa Network Prakarsa conducted a thorough assessment of PT. Saka Farma Laboratories’ existing infrastructure to identify compatibility issues and develop a tailored deployment plan. This involved evaluating hardware compatibility, network configurations, and data migration strategies.</p><br/><br/>

                        <p className="text-lg text-custom-paragraph">By simulating different scenarios and workload conditions, Nusa Network Prakarsa can fine- tune the sever configuration and optimize resource allocation to ensure optimal performance and stability during the actual deployment phase. Additionally, this approach allows for the development of comprehensive deployment plans and contingency measures to address any unforeseen challenges that may arise during the deployment process, thereby minimizing the risk of downtime and disruptions to PT. Saka Farma Laboratories’ operations.</p>

                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Conclusion</h3>
                        <p className="text-lg text-custom-paragraph">This deployment enhanced performance, scalability, and security while ensuring compliance with industry regulations. By addressing challenges such a legacy system integration, complex deployment, data security, and downtime mitigation, Nusa Network Prakarsa demonstrated its commitment to delivering innovative IT solutions that empower business to thrive in today’s digital landscape. Through this collaboration, PT. Saka Farma Laboratories successfully upgraded its server infrastructure, and it exemplifies the value of expertise, collaboration, and tailored solutions in driving technological advancements in the pharmaceutical industry.</p>

                    </div>
                    <div className="basis-1/4 relative lg:py-24 py-12">
                        <div className="lg:w-72 w-full border bg-custom-card p-6 rounded-2xl">
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Industry</span>
                                <span className="text-xl font-semibold">Education</span>
                            </div>
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Timeline</span>
                                <span className="text-xl font-semibold">2023</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base text-custom-paragraph">Solutions</span>
                                <span className="text-xl font-semibold">Professional Services</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='py-16'>
                    <h3 className="lg:text-4xl text-2xl font-bold mb-10">Read more our customer stories</h3>

                    <div className="flex lg:flex-row flex-col lg:space-x-4 lg:space-y-0 space-y-4 lg:mb-24">
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard3.png'})`}}>
                            <Link to="/project/Tereos-FKS-Indonesia">
                                <div className="flex flex-col justify-between h-full">
                                    <img className="" style={{height: '30px', width: '150px'}} src={process.env.PUBLIC_URL + '/img/blog/tereos.png'} />
                                    <h1 className="lg:text-3xl text-base font-semibold text-white">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard4.png'})`}}>
                            <Link to="/project/Saka-Farma-Laboratories">
                                <div className="flex flex-col justify-between h-full">
                                    <img className="" style={{height: '40px', width: '100px'}} src={process.env.PUBLIC_URL + '/img/blog/kalbe.png'} />
                                    <h1 className="lg:text-3xl text-base font-semibold text-white">From Bottlenecks to Breakthrough, PT. Saka Farma Laboratories Uncorks Efficiency with HPE ProLiant Servers</h1>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProjectDetails4