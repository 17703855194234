import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

function ProjectDetails2() {
    return(
        <>
            <div className="mx-custom-112 lg:block hidden">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
            </div>
            <div className="mx-4 lg:hidden sm:block">
                <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
            </div>
            <div className="w-full img-hero-fortigate lg:h-custom-516 h-custom-250"></div>
            <div className="lg:mx-custom-112 px-4">
                <div className="flex lg:flex-row flex-col-reverse">
                    <div className="basis-3/4 lg:pt-24 sm:pt-12 lg:px-custom-104">
                        <h3 className="text-custom-32 font-semibold mb-6">Background</h3>
                        <p className="text-lg text-custom-paragraph">PT. Tereos FKS Indonesia, a leading food manufacturing company, faced increasing challenges with their existing network security infrastructure. Their legacy system struggled to keep pace with their growing data volume, rising number of users, and evolving cyber threats. This exposed them to potential data breaches, operational disruptions, and reputational damage. Recognizing these risks and the need for a robust security solution, PT. Tereos FKS Indonesia sought the expertise of Nusa Network Prakarsa, a trusted IT solutions provider, to explore the implementation of a next-generation firewall.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Challenge</h3>
                        <p className="text-lg text-custom-paragraph">PT. Tereos FKS Indonesia’s existing network infrastructure, while reliable needed an upgrade to match the pace of modern cybersecurity threats. Integrating the cutting-edge Fortigate 200F into their systems presented compatibility challenges and potential disruptions. In a bid to fortify its digital fortress, PT. Tereos FKS Indonesia embarked on a transformative journey with the installation of the FortiGate 200F. This ambitious project sought to replace the aging existing device, elevating the standardization of content filters, particularly in the web filter and application control sections.</p>
                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Solution</h3>

                        <p className="text-lg text-custom-paragraph">The food manufacturing industry is increasingly targeted by cyber threats, including malware, ransomware, and data breaches. Ensuring robust protection against these evolving threats required implementing a firewall solution with advanced threat detection and prevention capabilities. Installing and configuring the next-gen firewall in their network environment demands specialized knowledge and expertise. Addressing the complexity of the deployment while minimizing downtime and disruptions to production processes poses a significant challenge.</p><br/><br/>

                        <p className="text-lg text-custom-paragraph">Nusa Network Prakarsa team dove deep into PT. Tereos FKS Indonesia’s network infrastructure, customizing a deployment plan that seamlessly integrated the Fortigate 200F while minimizing disruptions. The Fortigate 200F firewall was selected for its advanced threat detection and prevention features, including intrusion prevention, antivirus, web filtering, and application control. This comprehensive security suite provided PT. Tereos FKS Indonesia with proactive defense against a wide range of cyber threats.</p><br/><br/>

                        <h3 className="text-custom-32 font-semibold mt-16 mb-6">Conclusion</h3>

                        <p className="text-lg text-custom-paragraph">Replacing their outdated firewall with the Fortigate 200F proved to be a recipe for success for PT. Tereos FKS Indonesia. The upgraded security posture significantly reduced cyberattack risks, while centralized management and a user-friendly platform streamlined operations and saved IT resources. Additionally, the scalable architecture ensures the solution adapts to their evolving needs, making it a future-proof investment for their expanding digital kitchen. By proactively addressing cybersecurity challenges and leveraging advanced threat protection capabilities, PT. Tereos FKS Indonesia can mitigate the risk of cyber threats and safeguard its digital assets and sensitive data.</p>

                    </div>
                    <div className="basis-1/4 relative lg:py-24 py-12">
                        <div className="lg:w-72 w-full border bg-custom-card p-6 rounded-2xl">
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Industry</span>
                                <span className="text-xl font-semibold">Education</span>
                            </div>
                            <div className="flex flex-col mb-6">
                                <span className="text-base text-custom-paragraph">Timeline</span>
                                <span className="text-xl font-semibold">2023</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-base text-custom-paragraph">Solutions</span>
                                <span className="text-xl font-semibold">Professional Services</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='py-16'>
                    <h3 className="lg:text-4xl text-2xl font-bold mb-10">Read more our customer stories</h3>

                    <div className="flex lg:flex-row flex-col lg:space-x-4 lg:space-y-0 space-y-4 lg:mb-24">
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard3.png'})`}}>
                            <Link to="/project/Tereos-FKS-Indonesia">
                                <div className="flex flex-col justify-between h-full">
                                    <img className="" style={{height: '30px', width: '150px'}} src={process.env.PUBLIC_URL + '/img/blog/tereos.png'} />
                                    <h1 className="lg:text-3xl text-base font-semibold text-white">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
                                </div>
                            </Link>
                        </div>
                        <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard4.png'})`}}>
                            <Link to="/project/Saka-Farma-Laboratories">
                                <div className="flex flex-col justify-between h-full">
                                    <img className="" style={{height: '40px', width: '100px'}} src={process.env.PUBLIC_URL + '/img/blog/kalbe.png'} />
                                    <h1 className="lg:text-3xl text-base font-semibold text-white">From Bottlenecks to Breakthrough, PT. Saka Farma Laboratories Uncorks Efficiency with HPE ProLiant Servers</h1>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProjectDetails2