import "./style.css";
import { Link } from "react-router-dom";

function TechnologyPartnerComponent(props) {
    const downloadPdf = (logo_id) => {
        if (logo_id == 16 ) {
            window.location.href = "/contact";
        }
    };

    const conditionalBrandsBehavior = (id, path) => {
        if (id == 16) {
            return (
                <a href='/doc/obscape.pdf' download="Obscape Water & Environment Sectors 2023_NUSA">
                    <img 
                    style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
                    src={process.env.PUBLIC_URL + path} />
                </a>
            )
        } else if (id == 19) {
            return <Link to="/product/chainway" onClick={() => window.scrollTo(0, 0)}><img 
            style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
            src={process.env.PUBLIC_URL + path} /></Link>
        } else {
            return (
                <img 
                style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
                src={process.env.PUBLIC_URL + path} />
            )

        }

    }
    return (
        <>
            <section id="technology-partner" className="deskt">
                <div className="container deskt">
                    <h2 className="big-title">Our Technology <span style={{color: "#3B82F6"}}>Partner</span></h2>
                    <div id="technology-partner-company-list">
                            {props.data.map(logo => (
                                <div key={logo.id}>
                                    { conditionalBrandsBehavior(logo.id, logo.path) }
                                </div>
                            ))}
                    </div>
                </div>
            </section>
            <section id="technology-partner-mob" className="mob">
                <div className="container">
                    <p className="big-title">Our Technology Partner</p>
                    <div className="bungkus">
                        <article className="wrapper">
                            <div className="marquee">
                                <div className="marquee__group">
                                    {props.data.map(logo => (
                                        <div key={logo.id}>
                                            <img className="mob"
                                                style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
                                                src={process.env.PUBLIC_URL + logo.path} />
                                        </div>
                                    ))}
                                </div>
                                <div aria-hidden="true" className="marquee__group">
                                    {props.data.map(logo => (
                                        <div key={logo.id}>
                                            <img className="mob"
                                                style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
                                                src={process.env.PUBLIC_URL + logo.path} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="marquee marquee--reverse">
                                <div className="marquee__group">
                                    {props.data.map(logo => (
                                        <div key={logo.id}>
                                            <img className="mob"
                                                style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
                                                src={process.env.PUBLIC_URL + logo.path} />
                                        </div>
                                    ))}
                                </div>
                                <div aria-hidden="true" className="marquee__group">
                                    {props.data.map(logo => (
                                        <div key={logo.id}>
                                            <img className="mob"
                                                style={{marginRight:'32px', height: '80px', width: '180px', objectFit: 'contain'}}
                                                src={process.env.PUBLIC_URL + logo.path} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>

        </>
    );
}

export default TechnologyPartnerComponent;

    