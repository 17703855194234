import React, {Component} from "react"
import {SecuritySolutions, CTASolutions} from "../../components/"

export default class SecuritySolution extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});
    }
    render() {
        return (
            <>
                <SecuritySolutions/>
                <CTASolutions/>
            </>
        )
    }
}