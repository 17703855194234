import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const titleProject = {
    color: "var(--gray-900, #101828) !important",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    textDecoration: "none",
}
const ImageProject = styled.div`
    height: 296px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    ${props => setBackground(props)}
`
const setBackground = (props) => {
    console.log(props.background)
    return css`
        background-image: url('${props.background}');
    `
}

function ProjectCard (props) {
    return (
        <>
            <div className="row">
                {props.data && props.data.map(d => (
                    <div className="our-projects col-md-6" key={d.id}>
                        <ImageProject background={process.env.PUBLIC_URL + d.foto}/>
                        <Link to={`/project/${d.id}`} style={titleProject}>{d.judul}</Link>
                    </div>
                ))}
            </div>
        </>
    )
}
export default ProjectCard;