import React, {useEffect} from "react"
function SecuritySolutions() {
    return (
        <>
            <section id="SecuritySolution">
                <hgroup>
                    <span className="span-blue">Enterprise Security Solutions</span>
                    <h1 className="w-58">We protect your business network infrastructure</h1>
                    <div className="decor_radius_one"></div>
                    <div className="decor_radius_two"></div>
                </hgroup>

                <div className="decor-gallery">
                    <div className="decor-gallery-1"></div>
                    <div className="decor-gallery-2">
                        <img src={process.env.PUBLIC_URL + '/img/Frame_6974.svg'} />
                    </div>
                    <div className="decor-gallery-3"></div>
                </div>

                <div className="company-logo-list">
                    <img src={process.env.PUBLIC_URL + '/img/company1.svg'} />
                    <img src={process.env.PUBLIC_URL + '/img/company2.svg'} />
                    <img src={process.env.PUBLIC_URL + '/img/company3.svg'} />
                    <img src={process.env.PUBLIC_URL + '/img/company4.svg'} />
                    <img src={process.env.PUBLIC_URL + '/img/company5.svg'} />
                </div>

                <main>
                    <h3>Nusa Security Solution</h3>
                    <p>We know that your network is very important for your business to work well. We made strong and sophisticated security plans to protect your important digital things.</p>
                    <div className="hero-wrapper">
                        <div className="hero hero1">
                            Next-Generation Firewall
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_fire.svg'}/>
                        </div>
                        <div className="hero hero2">
                            Email Security Gateway
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_envelope-shield.svg'}/>
                        </div>
                        <div className="hero hero3">
                            Intrusion Prevention System (IPS)
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_padlock.svg'}/>
                        </div>
                        <div className="hero hero4">
                            Antivirus
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_virus-slash.svg'}/>
                        </div>
                        <div className="hero hero5">
                            SIEM + SOAR
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_channel.svg'}/>
                        </div>
                        <div className="hero hero6">
                            2 Factor Authentication (2FA)
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_keyhole-circle.svg'}/>
                        </div>
                        <div className="hero hero7">
                            Security Validation
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_shield-exclamation.svg'}/>
                        </div>
                        <div className="hero hero8">
                            Web Application Firewall
                            <img src={process.env.PUBLIC_URL + '/img/nss/uil_globe.svg'}/>
                        </div>
                    </div>
                </main>
            </section>
        </>
    );
}

export default SecuritySolutions;