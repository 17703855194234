import "./style.css";
import React, {useState} from "react"

function ContactFormComponent() {
    const [activeNotification, setActiveNotification] = useState('d-none')

    const showSuccessNotif = () => {
        setActiveNotification('d-block'); 
        setTimeout(myGreeting, 5000);
    };

    const myGreeting = () => {
        setActiveNotification('d-none'); 
    }
    return (
        <>
            <section id="contact_form" className="deskt">
                <div className={activeNotification} style={{position: "fixed", marginLeft: "450px", marginTop: "64px", padding: "12px", borderRadius: "64px", backgroundColor: "#22C55E", color: "#FFF"}}> <img src={process.env.PUBLIC_URL + '/img/Alert/ic_round-check-circle.png'} style={{marginRight: "24px"}} alt="check icon"/>Your message has been sent successfully!</div>
                <div className="form-contact">
                    <h1>Contact Us</h1>
                    <p>Have a question, a thought, or just want to say hi? We're all ears and can't wait to chat with you.</p>
                    <form>
                    <div className="form-group">
                        <label for="name">Name</label>
                        <input type="text" className="form-control" id="name" placeholder="Your Name" />
                    </div>
                    <div className="form-group">
                        <label for="email">Email</label>
                        <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="you@company.com" />
                    </div>
                    <div className="form-group">
                        <label for="message">Message</label>
                        <textarea className="form-control" placeholder="Tell us what you need" rows={5}></textarea>
                    </div>
                    
                    <button type="button" onClick={showSuccessNotif} className="btn btn-primary" style={{width: "100%"}}>Contact Us</button>
                    </form>
                </div>
                <div className="contact-banner"></div>
            </section>
            <div className="contact-banner"></div>
        </>
    );
}

export default ContactFormComponent;

